<script setup lang="ts">
import BasicDialog from '@/components/BasicDialog.vue'

import { useApi } from '@/services/api'

import { useNotificationStore } from '@/stores/notification'

import type { OrganizationFullModel } from '@/types/api/models/organization.full'
import type { SectionBaseModel } from '@/types/api/models/section.base'

import { isLeft } from '@/utils/either'

import SectionForm from './SectionForm.vue'


defineProps<{
  isOpen: boolean
  organizations: OrganizationFullModel[]
}>()

const emit = defineEmits<{ (e: 'close'): void }>()

const { section: sectionSvc } = useApi()
const notificationStore = useNotificationStore()

const onNewSection = (data: SectionBaseModel) => {
  sectionSvc.create(data)
    .then(either => {
      if (isLeft(either)) {
        return notificationStore.add({ error: true })
      }
      emit('close')
    })
    .catch(err => {
      notificationStore.add({ error: true, message: err })
    })
}
</script>

<template>
  <BasicDialog :is-open="isOpen" @close="$emit('close')">
    <template #title>New section</template>

    <template #main>
      <SectionForm
        :organizations="organizations"
        @cancel="$emit('close')"
        @new-section="onNewSection"
      />
    </template>
  </BasicDialog>
</template>
