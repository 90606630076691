import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useProgressStore = defineStore('progress', () => {
  const _count = ref(0)

  const _started = () => _count.value++

  const _finished = () => {
    if (_count.value > 0) {
      _count.value--
    }
  }

  const waiting = computed(() => _count.value > 0)

  const track = async <T>(p: Promise<T>) => {
    _started()
    return p
      .finally(() => _finished())
  }

  return { waiting, track }
})
