import { useAuthStore } from '@/stores/auth'
import { useProgressStore } from '@/stores/progress'

import type { OrganizationBaseModel } from '@/types/api/models/organization.base'
import type { OrganizationFullModel } from '@/types/api/models/organization.full'
import type { OrganizationTeamMemberFullModel } from '@/types/api/models/organization-team-member.full'
import type { SectionFullModel } from '@/types/api/models/section.full'

import type { OrganizationBase } from '@/types/api/raw/organization.base'
import type { OrganizationFull } from '@/types/api/raw/organization.full'
import type { OrganizationTeamMemberFull } from '@/types/api/raw/organization-team-member.full'
import type { SectionFull } from '@/types/api/raw/section.full'

import type { Either } from '@/types/either'

import { makeLeft, makeRight } from '@/utils/either'

import { useHttp } from '../util/http'
import { useSerialization } from '../util/serialization'
import type { UserCommon } from '@/types/api/raw/user.common'
import type { UserCommonModel } from '@/types/api/models/user.common'



type GetOrganizationsError = 'UNKNOWN_ERROR'
type CreateOrganizationError = 'UNKNOWN_ERROR'
type AddTeamMemberError = 'UNKNOWN_ERROR'
type InviteTeamMemberError = 'UNKNOWN_ERROR'
type UpdateTeamMemberError = 'UNKNOWN_ERROR'
type DeleteTeamMemberError = 'UNKNOWN_ERROR'
type Role = OrganizationTeamMemberFull['role']


const translateGetListError = (message: string): GetOrganizationsError => {
  switch (message) {
    default:
      return 'UNKNOWN_ERROR'
  }
}

const translateCreateError = (message: string): CreateOrganizationError => {
  switch (message) {
    default:
      return 'UNKNOWN_ERROR'
  }
}

const translateAddTeamMemberError = (message: string): AddTeamMemberError => {
  switch (message) {
    default:
      return 'UNKNOWN_ERROR'
  }
}

const translateUpdateTeamMemberError = (message: string): UpdateTeamMemberError => {
  switch (message) {
    default:
      return 'UNKNOWN_ERROR'
  }
}

const translateDeleteTeamMemberError = (message: string): DeleteTeamMemberError => {
  switch (message) {
    default:
      return 'UNKNOWN_ERROR'
  }
}


const { get, post, patch, delete: doDelete } = useHttp()

const sectionSrlz = useSerialization<SectionFull, SectionFullModel>()

const userDeserializer = useSerialization<UserCommon, UserCommonModel>()
  .makeDeserializer()
  .deserializeRecord

const teamSrlz = useSerialization<
  OrganizationTeamMemberFull,
  OrganizationTeamMemberFullModel
>({
  rawToModel: {
    replace: tm => {
      if (!tm.team_member) {
        throw new Error(`Team ${tm.id} missing team_member`)
      }
      return <OrganizationTeamMemberFullModel>{
        ...userDeserializer(tm.team_member),
        role: tm.role,
      }
    },
  },
})

const teamSerializer = teamSrlz.makeSerializer().serializeRecord

const cfg = {
  rawToModel: {
    values: {
      sections: sectionSrlz.makeDeserializer().deserializeArray,
      team: teamSrlz.makeDeserializer().deserializeArray,
    },
  },
  params: {
    include: { 'team': 'team.teamMember' },
  },
}
const srlz = useSerialization<OrganizationFull, OrganizationFullModel>(cfg)

const { serializeParams, serializeRecord } = srlz.makeSerializer()
const { deserializeArray } = srlz.makeDeserializer()


export const useOrganization = (route: string) => {
  const { getToken } = useAuthStore()
  const { track } = useProgressStore()

  const getList = async (params?: {
    include?: ('sections' | 'sections.lists' | 'sections.owner' | 'sections.staff' | 'team')[]
  }): Promise<Either<GetOrganizationsError, OrganizationFullModel[]>> => {
    try {
      const p = serializeParams(params)
      const res = await track(get<OrganizationFull[]>(route, p, getToken()))
      if ('statusCode' in res) {
        return makeLeft(translateGetListError(res.message))
      }
      return makeRight(deserializeArray(res))
    } catch (err) {
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  const create = async (
    data: OrganizationBaseModel,
  ): Promise<Either<CreateOrganizationError, 'ok'>> => {
    try {
      const p = serializeRecord(data)
      const t = getToken()
      const res = await track(post<OrganizationBase, OrganizationFull>(route, p, t))
      if ('statusCode' in res) {
        return makeLeft(translateCreateError(res.message))
      }
      return makeRight('ok')
    } catch (err) {
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  const addTeamMember = async (
    id: string,
    userId: string,
  ): Promise<Either<AddTeamMemberError, 'ok'>> => {
    try {
      const p = serializeRecord({ id: userId })
      const url = `${route}/${id}/team`
      const res = await track(post<{ id: string }, {}>(url, p, getToken()))
      if ('statusCode' in res) {
        return makeLeft(translateAddTeamMemberError(res.message))
      }
      return makeRight('ok')
    } catch (err) {
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  const inviteTeamMembers = async (
    organizationId: string,
    sectionIds: string[],
    role: Role,
    memberIds: string[],
    emails: string[],
  ): Promise<Either<InviteTeamMemberError, 'ok'>> => {
    try {
      const p = { sectionIds, role, memberIds, emails }
      const url = `${route}/${organizationId}/invite`
      const res = await track(post<{ sectionIds: string[], role: Role, memberIds: string[], emails: string[] }, {}>(url, p, getToken()))
      if ('statusCode' in res) {
        return makeLeft(translateAddTeamMemberError(res.message))
      }
      return makeRight('ok')
    } catch (err) {
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  const updateTeamMember = async (
    id: string,
    userId: string,
    payload: Pick<OrganizationTeamMemberFullModel, 'role'>,
  ): Promise<Either<UpdateTeamMemberError, 'ok'>> => {
    try {
      const url = `${route}/${id}/team/${userId}`
      const p = teamSerializer(payload)
      const res = await track(patch<typeof payload, {}>(url, p, getToken()))
      if ('statusCode' in res) {
        return makeLeft(translateUpdateTeamMemberError(res.message))
      }
      return makeRight('ok')
    } catch (err) {
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  const removeTeamMember = async (
    id: string,
    userId: string,
  ): Promise<Either<DeleteTeamMemberError, 'ok'>> => {
    try {
      const url = `${route}/${id}/team/${userId}`
      const res = await track(doDelete<{}>(url, getToken()))
      console.log('res:', res)
      if ('statusCode' in res) {
        return makeLeft(translateDeleteTeamMemberError(res.message))
      }
      return makeRight('ok')
    } catch (err) {
      console.log('err:', err)
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  return { getList, create, addTeamMember, inviteTeamMembers, updateTeamMember, removeTeamMember }
}
