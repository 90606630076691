import { ref, toValue, type Ref, computed } from 'vue'


enum WSState {
  CONNECTING,
  OPEN,
  CLOSING,
  CLOSED,
}

export type Socket = {
  connect: () => void
  send: (req: string, payload: any) => void
  disconnect: () => void
  isOpen: Ref<boolean>
  isClosed: Ref<boolean>
  message: Ref<MessageEvent | null>
  error: Ref<Event | null>
}


const createSocket = (
  url: string,
  token: Ref<string> | (() => string)
): Socket => {
  const socket = ref<WebSocket | null>(null)
  const message = ref<MessageEvent | null>(null)
  const error = ref<Event | null>(null)
  let backoffDelay = 100

  const reconnect = () => {
    setTimeout(() => connect(), backoffDelay)
    backoffDelay = Math.min(backoffDelay * 2, 30000)
  }

  const resetBackoff = () => {
    backoffDelay = 100
  }

  const connect = () => {
    console.log(`${Date.now()} websocket.ts:41\turl:\t`, url);
    console.log(`${Date.now()} websocket.ts:42\ttoken:\t`, token);
    console.log(`${Date.now()} websocket.ts:43\ttoValue(token):\t`, toValue(token));
    console.log(`${Date.now()} websocket.ts:44\t${url}?token=${toValue(token)}:\t`, `${url}?token=${toValue(token)}`);
    const s = new WebSocket(`${url}?token=${toValue(token)}`)
    s.onclose = () => reconnect()
    s.onopen = () => resetBackoff()
    s.onmessage = (e) => {
      message.value = e
    }
    s.onerror = (e) => error.value = e
    socket.value = s
  }

  const send = (req: string, payload: any) => {
    if (!socket.value || socket.value.readyState !== WSState.OPEN) {
      return
    }
    socket.value.send(JSON.stringify({ req, payload }))
  }

  const disconnect = () => {
    if (!socket.value || socket.value.readyState === WSState.CLOSED) {
      return
    }
    socket.value.onopen = null
    socket.value.onerror = null
    socket.value.onmessage = null
    socket.value.onclose = null

    socket.value.close()
  }

  const isOpen = computed(() => {
    return !!socket.value && socket.value.readyState === WSState.OPEN
  })

  const isClosed = computed(() => {
    return !!socket.value && socket.value.readyState === WSState.CLOSED
  })

  return {
    connect,
    send,
    disconnect,
    isOpen,
    isClosed,
    message,
    error,
  }
}

const sockets: { [url: string]: Socket } = {}

export const useWebSocket = (
  url: string,
  token: Ref<string> | (() => string),
) => {
  if (!sockets[url] || sockets[url].isClosed.value) {
    sockets[url] = createSocket(url, token)
  }

  const socket = ref(sockets[url])

  return { socket }
}
