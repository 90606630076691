<script setup lang="ts">
import { computed, ref, watch, onUnmounted, type Ref } from 'vue'

import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

import { chain } from 'lodash'

import { useApi } from '@/services/api'
import { useWs } from '@/services/ws'

import { useNotificationStore } from '@/stores/notification'
import { useUnreadMessageStore } from '@/stores/unread-message'

import type { SectionFullModel } from '@/types/api/models/section.full'
import type { SectionMessageBaseModel } from '@/types/api/models/section-message.base'
import type { SectionMessageFullModel } from '@/types/api/models/section-message.full'

import { isLeft, unwrapEither } from '@/utils/either'

import SectionsPane from './SectionsPane.vue'
import MessagesPane from './MessagesPane.vue'


const { section: sectionSvc } = useApi()
const { sectionMessage: sectionMessageWs } = useWs()
const notificationStore = useNotificationStore()
const unreadMessageStore = useUnreadMessageStore()

const sections = ref<SectionFullModel[]>([])

/**
* Array of messages as returned by backend (i.e. ordered by createdAt, DESC)
*/
const messages = ref<SectionMessageFullModel[]>([]) // selected section's messages

const markRead = (ids: Set<string>) => {
  if (!ids.size) {
    return;
  }
  const ms = messages.value.filter(m => ids.has(m.id))
  sectionMessageWs.markRead(ms)
  unreadMessageStore.markRead(ids)
}

const selectedSectionId = ref('')

const onSelectSection = (id: string) => {
  selectedSectionId.value = id
  changeTab(1)  // ignored in web UI
}

const selectedSection = computed(() =>
  sections.value.find(s => s.id === selectedSectionId.value)
)

watch(selectedSectionId, (sectionId) => {
  messages.value = []
  loadMessages(sectionId)
})

sectionMessageWs.connect()
onUnmounted(() => { sectionMessageWs.disconnect() })

watch(sectionMessageWs.updatedMessage, message => {
  const sectionId = selectedSectionId.value
  if (message?.sectionId === sectionId) {
    mergeMessages([message], messages)
  }
})

const postMessage = (message: SectionMessageBaseModel) => {
  sectionMessageWs.post(selectedSectionId.value, message)
}

const loadSections = () => {
  sectionSvc.getList({
    include: ['owner', 'staff', 'organization', 'organization.team'],
  })
    .then(either => {
      if (isLeft(either)) {
        console.log('error loading sections')
        return notificationStore.add({ error: true })
      }
      const ss = unwrapEither(either)
      sections.value = ss
    })
}

const mergeMessages = (newMessages: SectionMessageFullModel[], messages: Ref<SectionMessageFullModel[]>) => {
  if (!newMessages.length) {
    return;
  }

  console.log('merging messages...')

  const currentMessages = messages.value.slice()
  if (!currentMessages.length) {
    messages.value = newMessages
    return;
  }

  const newMessageIds = new Set(newMessages.map(m => m.id))
  const merged = chain(currentMessages)
    .filter(m => !newMessageIds.has(m.id))
    .concat(newMessages)
    .orderBy('createdAt', 'desc')
    .value()

  console.log('merged:', merged)

  messages.value = merged
}

const loadMessages = (sectionId: string, createdBefore?: Date) => {
  const args: Parameters<typeof sectionSvc.getMessages>[1] = {
    include: ['author', 'viewedBy'],
  }
  if (createdBefore) {
    args.before = new Date(createdBefore.getTime() + 1000)
    args.limit = 15
  }

  sectionSvc.getMessages(sectionId, args)
    .then(either => {
      if (isLeft(either)) {
        console.log('error loading messages')
        return notificationStore.add({ error: true })
      }

      mergeMessages(unwrapEither(either), messages)
    })
}

loadSections()

// ------------- For use with tabs in mobile view START -------------
const selectedTab = ref<number>(0)

const changeTab = (idx: number) => selectedTab.value = idx
// ------------- For use with tabs in mobile view END -------------
</script>

<template>
  <CommonShell>
    <h1 class="py-2">Messages</h1>

    <div class="flex md:hidden flex-col flex-1 rounded bg-default">
      <TabGroup :selected-index="selectedTab" @change="changeTab">

        <TabList class="hidden">

          <Tab
            :class="selectedTab === 0 ? 'bg-light' : 'bg-amber-600'"
            class="rounded py-2 w-36"
          >
            Sections
          </Tab>

          <Tab
            :class="selectedTab === 1 ? 'bg-light' : 'bg-amber-600'"
            class="rounded py-2 w-36"
          >
            Messages
          </Tab>

        </TabList>

        <TabPanels class="flex flex-1">

          <TabPanel class="flex flex-1 max-h-[calc(100vh-12rem)]">
            <SectionsPane
              :messages="unreadMessageStore.allMessages"
              :selected-section-id="selectedSectionId"
              :sections="sections"
              @select-section="onSelectSection"
            />
          </TabPanel>

          <TabPanel class="flex">
            <MessagesPane
              :messages="messages"
              :section-staff="selectedSection?.staff || []"
              :section-name="selectedSection?.name"
              @load-old-messages="(before : Date) => loadMessages(selectedSectionId, before)"
              @post-message="postMessage"
              @viewed-messages="markRead"
              @go-back="() => changeTab(0)"
              class="max-h-[calc(100vh-12rem)]"
            />
          </TabPanel>

        </TabPanels>

      </TabGroup>
    </div>

    <div class="hidden md:flex flex-row flex-1 rounded bg-default max-h-[calc(100vh-10.5rem)]">

      <SectionsPane
        :messages="unreadMessageStore.allMessages"
        :selected-section-id="selectedSectionId"
        :sections="sections"
        @select-section="onSelectSection"
      />

      <MessagesPane
        :messages="messages"
        :section-staff="selectedSection?.staff || []"
        @load-old-messages="(before: Date) => loadMessages(selectedSectionId, before)"
        @post-message="postMessage"
        @viewed-messages="markRead"
      />

    </div>
  </CommonShell>
</template>
