<script setup lang="ts">
import { ref, onUnmounted, computed } from 'vue'

import { orderBy } from 'lodash'
import { isBefore, isSameDay } from 'date-fns'

import BasicDialog from '@/components/BasicDialog.vue'
import SelectDialog from '@/components/SelectDialog.vue'

import { useApi } from '@/services/api'
import { useWs } from '@/services/ws'

import { useNotificationStore } from '@/stores/notification'
import { useAccountStore } from '@/stores/account'

import type { OrganizationFullModel } from '@/types/api/models/organization.full'
import type { PrepItemBaseModel } from '@/types/api/models/prep-item.base'
import type { PrepListBaseModel } from '@/types/api/models/prep-list.base'
import type { PrepListSettableModel } from '@/types/api/models/prep-list.settable'
import type { SectionListBase } from '@/types/api/raw/section-list'
import type { Model } from '@/types/serialization'

import { isLeft, unwrapEither } from '@/utils/either'

import PrepListForm from './PrepListForm.vue'
import SectionListForm from './SectionListForm.vue'
import PrepListList from './PrepListList.vue'
import PrepListInProgressModal from './PrepListInProgressModal.vue'
import PrepListUpdateModal from './PrepListUpdateModal.vue'
import type { PrepListFullModel } from '@/types/api/models/prep-list.full'
import {Tab, TabGroup, TabList, TabPanel, TabPanels} from "@headlessui/vue";


const prepLists = computed(() => {
  return orderBy(prepListWs.lists.value, 'dueAt', 'asc')
})

const { sectionMessage: sectionMessageWs } = useWs()
const { organization: organizationApi } = useApi()

const notificationStore = useNotificationStore()
const accountStore = useAccountStore()

const organizations = ref<OrganizationFullModel[]>([])
function fetchOrgs() {
  organizationApi.getList({ include: ['sections.lists', 'sections.staff', 'team'] })
    .then(either => {
      if (isLeft(either)) {
        return notificationStore.add({ error: true })
      }
      const orgs = unwrapEither(either)
      console.log('orgs:', orgs)
      organizations.value = orgs
    })
}
fetchOrgs()

const openModal = ref<'create' | 'update' | 'in-progress' | 'task' | null>(null)

const updatePrepList = (data: PrepListSettableModel) => {
  if (!selectedListId.value) {
    notificationStore.add({ message: 'No list selected', error: true })
    return;
  }
  const success = prepListWs.updateList(selectedListId.value, data)
  if (!success) {
    notificationStore.add({ message: 'Not connected to server', error: true })
  }
}

const publishPrepList = () => {
  openModal.value = null
  if (!selectedListId.value) {
    notificationStore.add({ message: 'No list selected', error: true })
    return;
  }
  const data = { published: true }
  const success = prepListWs.updateList(selectedListId.value, data)
  if (!success) {
    notificationStore.add({ message: 'Not connected to server', error: true })
  } else {
    sectionMessageWs.post(selectedList.value!.sectionId!, {text: `Prep-list published: ${selectedList.value!.name}`})
  }
}

const addPrepItem = (data: PrepItemBaseModel) => {
  if (!selectedListId.value) {
    notificationStore.add({ message: 'No list selected', error: true })
    return;
  }
  const success = prepListWs.createItem(selectedListId.value, data)
  if (!success) {
    notificationStore.add({ message: 'Not connected to server', error: true })
  }
}

const updatePrepItem = (data: { id: string; finished: boolean }) => {
  if (!selectedListId.value) {
    notificationStore.add({ message: 'No list selected', error: true })
    return;
  }
  const { id, ..._dat } = data
  const success = prepListWs.updateItem(selectedListId.value, id, _dat)
  if (!success) {
    notificationStore.add({ message: 'Not connected to server', error: true })
  }
}

const deletePrepItem = (id: string) => {
  if (!selectedListId.value) {
    notificationStore.add({ message: 'No list selected', error: true })
    return;
  }
  const success = prepListWs.deleteItem(selectedListId.value, id)
  if (!success) {
    notificationStore.add({ message: 'Not connected to server', error: true })
  }
}

const createPrepList = (data: PrepListBaseModel) => {
  console.log('close event fired with data:', data)
  openModal.value = null
  const success = prepListWs.createList(data.sectionId, data)
  if (!success) {
    notificationStore.add({ message: 'Not connected to server', error: true })
  } else if (data.published) {
    sectionMessageWs.post(data.sectionId, {text: `New prep-list published: ${data.name}`})
  }
}

const deletePrepList = () => {
  openModal.value = null
  if (!selectedListId.value) {
    notificationStore.add({ message: 'No list selected', error: true })
    return;
  }
  const success = prepListWs.deleteList(selectedListId.value)
  if (!success) {
    notificationStore.add({ message: 'Not connected to server', error: true })
  }
}

const createSectionList = (data: Model<SectionListBase>) => {
  console.log('close event fired with data:', data)
  openModal.value = null
  const success = prepListWs.createSectionList(data)
  if (!success) {
    notificationStore.add({ message: 'Not connected to server', error: true })
  } else {
    fetchOrgs()
  }
}

const updateSectionList = (data: Model<SectionListBase> & { listId: string }) => {
  console.log('close event fired with data:', data)
  openModal.value = null
  const success = prepListWs.updateSectionList(data)
  if (!success) {
    notificationStore.add({ message: 'Not connected to server', error: true })
  } else {
    fetchOrgs()
  }
}

const selectedListId = ref<string | null>(null)
const shouldShowAllPast = ref(false)

const onListSelected = (id: string) => {
  console.log('onListSelected called with id:', id)
  selectedListId.value = id
  openModal.value = 'task'
}

const categories = computed(() => {
  const retval = {
    past: { label: 'Past', lists: [] as PrepListFullModel[] },
    overdue: { label: 'Overdue', lists: [] as PrepListFullModel[] },
    today: { label: 'Today', lists: [] as PrepListFullModel[] },
    future: { label: 'Future', lists: [] as PrepListFullModel[] },
  }
  if (!prepListWs.lists.value) {
    return retval
  }
  const now = Date.now()
  for (const list of prepListWs.lists.value) {
    if (isBefore(list.dueAt, now)) {
      if (now < 24 * 60 * 60 * 1000 + list.dueAt.getTime() && list.prepItems.some(i => !i.finished)) {
        retval.overdue.lists.push(list)
      } else {
        retval.past.lists.push(list)
      }
    } else if (isSameDay(list.dueAt, now)) {
      retval.today.lists.push(list)
    } else {
      retval.future.lists.push(list)
    }
  }
  return retval
})

const selectedList = computed(() => {
  if (!prepLists.value || !selectedListId.value) {
    return null
  }
  return prepLists.value.find(l => l.id === selectedListId.value)
})

const { prepList: prepListWs } = useWs()

prepListWs.connect()
onUnmounted(() => { prepListWs.disconnect() })
</script>

<template>
  <CommonShell>
    <div class="flex flex-col">
      <img src="@/assets/images/prep-list-bg.jpg">

      <div class="flex flex-row py-2">
        <h1>Preplists</h1>
        <button
          type="button"
          @click="openModal = 'create'"
          class="ml-auto btn-primary"
        >
          Create
        </button>
      </div>
      <button type="button" class="ml-2 float-right" @click="shouldShowAllPast = !shouldShowAllPast" v-if="categories.past.lists.length">
        {{ shouldShowAllPast ? 'Show less' : 'Show more' }}
      </button>
      <table class="table-auto divide-zinc-900 divide-y">
        <template v-for="category in categories" :key="category.label">
          <template v-if="category.lists.length > 0 && (category !== categories.past || shouldShowAllPast)">
            <tr>
              <td colspan="8" class="bg-default">
                {{ category.label }}
              </td>
            </tr>

            <PrepListList
              class="bg-default rounded-md"
              :prep-lists="category.lists"
              @list-selected="onListSelected"
            />
          </template>
        </template>
      </table>
    </div>

    <BasicDialog :is-open="openModal === 'create'" @close="openModal = null">
      <template #title>
        <PrepListIcon class="mr-2" />
        New Preplist
      </template>

      <template #main>
        <!--        tabs for prep list vs section list -->

        <!--        prep list tab: -->
        <!--        section list <select> including empty list, grouped by section and org -->
        <!--        date picker -->
        <!--        name input -->
        <!--        prep item inputs -->
        <!--        blank prep item input -->
        <!--        handover textarea -->
        <!--        save draft and publish buttons -->

        <!--        section list tab: -->
        <!--        section list <select> including new list, grouped by section and org -->
        <!--        name input -->
        <!--        prep item inputs -->
        <!--        blank prep item input -->
        <!--        save section list button -->
        <TabGroup>
          <TabList>
            <Tab class="px-4 py-2 rounded-t-md hover:bg-dark outline-none border-light ui-selected:border-b-default -mb-0.5">Prep List</Tab>
<!--            TODO: disable for non-admin non-manager-->
            <Tab class="px-4 py-2 rounded-t-md hover:bg-dark outline-none border-light ui-selected:border-b-default -mb-0.5">Section List</Tab>
          </TabList>
          <TabPanels class="px-4 py-2 border-light">
            <TabPanel>
              <PrepListForm
                :organizations="organizations"
                :user-id="accountStore.user?.id"
                @new-prep-list="createPrepList"
                :prep-lists="prepLists"
              />
            </TabPanel>
            <TabPanel>
              <SectionListForm
                :organizations="organizations"
                :user-id="accountStore.user?.id"
                @new-section-list="createSectionList"
                @update-section-list="updateSectionList"
              />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </template>
    </BasicDialog>

    <SelectDialog :is-open="openModal === 'task'" @close="openModal = null">
      <template #main>
        <div class="flex flex-col items-start">
          <button type="button" class="mb-4" @click="openModal = 'in-progress'">
            View
          </button>
          <button type="button" class="mb-4" @click="openModal = 'update'">Edit</button>
          <button
            v-if="!selectedList?.published"
            type="button"
            class="mb-4"
            @click="publishPrepList"
          >
            Publish
          </button>
          <button type="button" @click="deletePrepList">Delete</button>
        </div>
      </template>
    </SelectDialog>

    <template v-if="selectedList">
      <PrepListInProgressModal
        :list="selectedList"
        :is-open="openModal === 'in-progress'"
        @close="openModal = null"
        @update-item="updatePrepItem"
        @new-item="addPrepItem"
      />

      <PrepListUpdateModal
        :list="selectedList"
        :is-open="openModal === 'update'"
        @close="openModal = null"
        @update="updatePrepList"
        @new-item="addPrepItem"
        @delete-item="deletePrepItem"
      />
    </template>

    <template v-else-if="openModal === 'in-progress' || openModal === 'update'">
      <BasicDialog
        :is-open="openModal === 'in-progress' || openModal === 'update'"
        @close="openModal = null"
      >
        <template #title>List not found</template>

        <template #main>
          <p>That prep-list is not currently available.</p>
        </template>
      </BasicDialog>
    </template>
  </CommonShell>
</template>
