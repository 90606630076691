import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import { chain } from 'lodash'

import type { SectionMessageFullModel } from '@/types/api/models/section-message.full'

import { useAccountStore } from './account'

const allMessages = ref<SectionMessageFullModel[]>([])
const messages = ref<SectionMessageFullModel[]>([])

const messagesBySection = computed(() => {
  return messages.value.reduce((acc, msg) => {
    if (!acc[msg.sectionId]) {
      acc[msg.sectionId] = []
    }
    acc[msg.sectionId].push(msg)
    return acc
  }, <{ [sectionId: string]: SectionMessageFullModel[] }>{})
})

export const useUnreadMessageStore = defineStore('unread-message', () => {
  /**
   * ALWAYS use __this__ function to add messages to ensure collection of messages
   * is de-duplicated and properly ordered.
   */
  const addMessages = (newMessages: SectionMessageFullModel[]) => {
    const accountStore = useAccountStore()
    const userId = accountStore.user?.id

    allMessages.value = chain(allMessages.value.concat(newMessages))
      .orderBy(['id', 'updatedAt'], ['desc', 'desc'])
      .reduce((acc, msg) => {
        if (!acc.length) {
          acc.push(msg)
          return acc
        }
        const lastMessage = acc[acc.length - 1]
        if (lastMessage.id !== msg.id) {
          acc.push(msg)
        }
        return acc
      }, <SectionMessageFullModel[]>[])
      .orderBy('createdAt', 'desc')
      .value()
    messages.value = allMessages.value.filter(m => !userId || !m.viewedBy?.some(u => u.id === userId))
  }

  const getSectionMessages = (sectionId: string) => {
    return messagesBySection.value[sectionId] || []
  }

  const markRead = (readMessageIds: Set<string>) => {
    messages.value = messages.value.filter(m => !readMessageIds.has(m.id))
  }

  return { allMessages, messages, addMessages, getSectionMessages, markRead }
})
