<script setup lang="ts">
import BasicDialog from '@/components/BasicDialog.vue'

import { useApi } from '@/services/api'

import { useNotificationStore } from '@/stores/notification'

import type { OrganizationFullModel } from '@/types/api/models/organization.full'
import type { OrganizationTeamMemberFull } from '@/types/api/raw/organization-team-member.full';

import { isLeft, unwrapEither } from '@/utils/either'

import TeamBulkAddForm from './TeamBulkAddForm.vue'

type Role = OrganizationTeamMemberFull['role']

defineProps<{
  isOpen: boolean
  organizations: OrganizationFullModel[]
}>()

const emit = defineEmits<{ (e: 'close'): void }>()

const notificationStore = useNotificationStore()

const {
  organization: organizationSvc,
} = useApi()

const onNewTeamMembers = async (data: {
  organizationId: string
  sectionIds: string[]
  role: Role
  memberIds: string[]
  emails: string[]
  callback: Function
}) => {
  const { organizationId, sectionIds, role, memberIds, emails, callback } = data

  organizationSvc.inviteTeamMembers(organizationId, sectionIds, role, memberIds, emails)
    .then(either => {
      if (isLeft(either)) {
        notificationStore.add({ error: true })
      }
      callback()
    })
}
</script>

<template>
  <BasicDialog :is-open="isOpen" @close="$emit('close')">
    <template #title>Add team members</template>

    <template #main>
      <TeamBulkAddForm
        :organizations="organizations"
        @close="$emit('close')"
        @new-team-members="onNewTeamMembers"
      />
    </template>
  </BasicDialog>
</template>
