import type { SnakeCase } from '@/types/serialization'


type RecursivePartial<T> = {
  [K in keyof T]?: RecursivePartial<T[K]>
}

type ValuesConfig<T> = {
  [K in keyof T]?: (value: NonNullable<T[K]>) => any
}

type ParamsConfig = {
  include?: { [key: string]: string }
}

type ConverterConfig<T, U> = {
  values?: ValuesConfig<T>
  replace?: (item: RecursivePartial<T>) => U
}

type Config<T, U> = {
  rawToModel?: ConverterConfig<T, U>
  modelToRaw?: ConverterConfig<U, T>
  params?: ParamsConfig,
}

type RecordConverter<T, U> = (item: RecursivePartial<T>) => U

type ArrayConverter<T, U> = (items: T[]) => U[]


const snakeCase = <T extends string>(s: T): SnakeCase<T> => {
  return <SnakeCase<T>>s.replace(/([A-Z]+)/g, (_, c) => `_${c.toLowerCase()}`)
}

const camelCase = (s: string) => s.replace(/_(\w)/g, (_, c) => c.toUpperCase())


const makeConverters = <T, U>(
  defaultKeyConverter: (k: string) => string,
  config?: ConverterConfig<T, U>,
) => {
  const convertValue = (value: T[keyof T], key: keyof T): U[keyof U] => {
    if (!config?.values) {
      return <any>value
    }
    const transform = config.values[key]
    if (!transform) {
      return <any>value
    }
    return transform(<NonNullable<T[keyof T]>>value)
  }

  const convertRecord: RecordConverter<T, U> = item => {
    if (config?.replace) {
      return config.replace(item)
    }
    const serialized: any = {}
    for (const k in item) {
      serialized[defaultKeyConverter(k)] = convertValue(<T[keyof T]>item[k], k)
    }
    return serialized
  }

  const convertArray: ArrayConverter<T, U> = items => {
    return items.map(i => convertRecord(i))
  }

  return { convertRecord, convertArray }
}

export const useSerialization = <Raw, Model>(config?: Config<Raw, Model>) => {
  const makeSerializer = () => {
    const converters = makeConverters<Model, Raw>(snakeCase, config?.modelToRaw)

    const {
      convertRecord: serializeRecord,
      convertArray: serializeArray,
    } = converters

    const serializeParams = <T extends string>(
      params?: { include?: T[] }
    ): { include?: (SnakeCase<string>)[] } | undefined => {
      if (!params?.include) {
        return
      }
      const configured = config?.params?.include
      return {
        include: params.include.map(i => {
          return snakeCase(configured && configured[i] ? configured[i] : i)
        })
      }
    }

    return { serializeRecord, serializeArray, serializeParams }
  }

  const makeDeserializer = () => {
    const converters = makeConverters<Raw, Model>(camelCase, config?.rawToModel)

    const {
      convertRecord: deserializeRecord,
      convertArray: deserializeArray,
    } = converters

    return { deserializeRecord, deserializeArray }
  }

  return { makeSerializer, makeDeserializer }
}
