import { createApp } from 'vue'
import { createPinia } from 'pinia'

import { Form, Field, FieldArray, ErrorMessage } from 'vee-validate'
import * as Sentry from "@sentry/vue";

import CommonShell from '@/components/CommonShell.vue'
import PrepListIcon from '@/components/icons/PrepListIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import * as faLibrary from '@/utils/fa-icon-library'

import App from './App.vue'
import router from './router'

import './assets/main.css'

const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://e24c5659dd9537778bc3b3a5b6302b62@o4508170507780096.ingest.us.sentry.io/4508170522984449",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/([^/]*\.)?hospa\.co/, /^https:\/\/hospa\.smartcitiestransport\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

console.log(`${Date.now()} main.ts\t22\tapp.use(createPinia())`);
app.use(createPinia())
app.use(router)

faLibrary.initialize()
app.component('font-awesome-icon', FontAwesomeIcon)

app
  .component('CommonShell', CommonShell)
  .component('PrepListIcon', PrepListIcon)
  .component('EditIcon', EditIcon)
  .component('CloseIcon', CloseIcon)

app
  .component('Form', Form)
  .component('Field', Field)
  .component('FieldArray', FieldArray)
  .component('ErrorMessage', ErrorMessage)

app.mount('#app')

app.config.errorHandler = (err, vm, info) => {
  console.error(`${Date.now()}`, err, vm, info)
}
