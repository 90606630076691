import { useAuthStore } from '@/stores/auth'
import { useProgressStore } from '@/stores/progress'

import type { Either } from '@/types/either'
import type { LoginError, SignupError } from '@/types/auth.errors'

import { makeLeft, makeRight } from '@/utils/either'

import { useHttp } from './util/http'


type TokenRes = { token: string }


const translateLoginError = (message: string): LoginError => {
  switch (message) {
    case '__INVALID_LOGIN__':
      return 'INVALID_CREDENTIALS'
    default:
      return 'UNKNOWN_ERROR'
  }
}

const translateSignupError = (message: string): SignupError => {
  switch (message) {
    case '__EMAIL_ALREADY_EXISTS__':
      return 'EMAIL_EXISTS'
    default:
      return 'UNKNOWN_ERROR'
  }
}

export const useAuth = () => {
  const { setToken } = useAuthStore()
  const { post } = useHttp()
  const { track } = useProgressStore()

  const baseUrl = `${import.meta.env.VITE_BACKEND_URL || ''}/auth`

  const login = async (
    values: { email: string; password: string },
  ): Promise<Either<LoginError, 0>> => {
    try {
      const url = `${baseUrl}/login`
      const res = await track(post<typeof values, TokenRes>(url, values))
      if ('statusCode' in res) {
        return makeLeft(translateLoginError(res.message))
      }
      setToken(res.token)
      return makeRight(0)
    } catch (err) {
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  const signup = async (values: {
    name: string;
    email: string;
    password: string;
  }): Promise<Either<SignupError, 0>> => {
    try {
      const url = `${baseUrl}/signup`
      const res = await track(post<typeof values, TokenRes>(url, values))
      if ('statusCode' in res) {
        return makeLeft(translateSignupError(res.message))
      }
      setToken(res.token)
      return makeRight(0)
    } catch (err) {
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  return { login, signup }
}
