<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { useApi } from '@/services/api'

import { useAccountStore } from '@/stores/account'
import { useNotificationStore } from '@/stores/notification'

import type { OrganizationFullModel } from '@/types/api/models/organization.full'

import { isLeft, unwrapEither } from '@/utils/either'

import ConnectionCard from './ConnectionCard.vue'
import SectionCreateModal from './section/SectionCreateModal.vue'
import SectionUpdateModal from './section/SectionUpdateModal.vue'
import TeamBulkAddModal from './team/TeamBulkAddModal.vue'
import TeamMemberDetailsModal from './team/TeamMemberDetailsModal.vue'


type ConnectionHomeModal =
  | 'section-create'
  | 'section-update'
  | 'team-bulk-add'
  | 'team-member-details'


const { organization: organizationSvc } = useApi()

const accountStore = useAccountStore()
const notificationStore = useNotificationStore()

const openModal = ref<ConnectionHomeModal | null>(null)

const sectionView = ref(false)

const selectedOrganizationId = ref<string | null>()
const selectedSectionId = ref<string | null>()
const selectedTeamMemberId = ref<string | null>()

const selectedOrganization = computed(() => {
  if (!selectedOrganizationId.value || !organizations.value) {
    return null
  }
  const organizationId = selectedOrganizationId.value
  return organizations.value.find(o => o.id === organizationId) || null
})

const selectedSection = computed(() => {
  if (!selectedSectionId.value || !selectedOrganization.value) {
    return null
  }
  const org = selectedOrganization.value
  const sectionId = selectedSectionId.value
  return org.sections?.find(s => s.id === sectionId) || null
})

const selectedTeamMember = computed(() => {
  if (!selectedTeamMemberId.value || !selectedOrganization.value) {
    return null
  }
  const org = selectedOrganization.value
  const teamMemberId = selectedTeamMemberId.value
  return org.team?.find(m => m.id === teamMemberId)
})

const openSectionDetails = (organizationId: string, sectionId: string) => {
  selectedOrganizationId.value = organizationId
  selectedSectionId.value = sectionId
  openModal.value = 'section-update'
}

const openTeamMemberDetails = (organizationId: string, userId: string) => {
  selectedOrganizationId.value = organizationId
  selectedTeamMemberId.value = userId
  openModal.value = 'team-member-details'
}

const organizations = ref<OrganizationFullModel[]>([])

const loadOrganizations = () => {
  return organizationSvc.getList({
    include: ['sections.owner', 'sections.staff', 'team'],
  })
    .then(either => {
      if (isLeft(either)) {
        return notificationStore.add({ error: true })
      }
      const orgs = unwrapEither(either)
      console.log('orgs:', orgs)
      organizations.value = orgs
    })
}
loadOrganizations().then(() => {
  const user = accountStore.user
  if (organizations.value.every(o => o.sections.length === 0 && o.team.find(t => t.id === user!.id)?.role === 'admin')) {
    openModal.value = 'team-bulk-add'
  }
})

watch(openModal, (value, oldValue) => {
  if (oldValue && !value) {
    loadOrganizations()
  }
})

function getSortedSections(org: OrganizationFullModel) {
  return org.sections.sort((a, b) => a.name.localeCompare(b.name))
}

function getSortedTeam(org: OrganizationFullModel) {
  return org.team.sort((a, b) => a.name.localeCompare(b.name))
}
</script>

<template>
  <CommonShell>
    <div class="flex flex-col w-full py-2">
      <img src="@/assets/images/connection-bg.jpg">

      <div class="flex flex-col md:flex-row py-2">
        <h1>Connections</h1>

        <div class="md:ml-auto">
          <button
            type="button"
            :class="[sectionView ? 'btn-plain' : 'btn-accent', 'mr-2']"
            @click="sectionView = false"
          >
            Team
          </button>

          <button
            type="button"
            :class="[sectionView ? 'btn-accent' : 'btn-plain', 'mr-2']"
            @click="sectionView = true"
          >
            Sections
          </button>

          <button
            type="button"
            @click="openModal = sectionView ? 'section-create' : 'team-bulk-add'"
            class="btn-primary"
          >
            Add New
          </button>
        </div>
      </div>
    </div>
    <div v-for="org in organizations" :key="org.id" class="my-4">
      {{ org.name }}
      <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
        <template v-if="sectionView">
          <button
            v-for="s in getSortedSections(org)"
            :key="s.id"
            @click="openSectionDetails(org.id, s.id)"
            type="button"
          >
            <ConnectionCard :name="s.name" />
          </button>
        </template>
        <template v-else>
          <button
            v-for="t in getSortedTeam(org)"
            :key="t.id"
            @click="openTeamMemberDetails(org.id, t.id)"
            type="button"
          >
            <ConnectionCard :name="t.name" />
          </button>
        </template>
      </div>
    </div>

    <SectionCreateModal
      :is-open="openModal === 'section-create'"
      :organizations="organizations"
      @close="openModal = null"
    />

    <SectionUpdateModal
      v-if="selectedOrganization && selectedSection"
      :is-open="openModal === 'section-update'"
      :organization="selectedOrganization"
      :section="selectedSection"
      @refresh="loadOrganizations"
      @close="openModal = null"
    />

    <TeamBulkAddModal
      :is-open="openModal === 'team-bulk-add'"
      :organizations="organizations"
      @close="openModal = null"
    />

    <TeamMemberDetailsModal
      v-if="selectedOrganizationId && selectedTeamMember"
      :is-open="openModal === 'team-member-details'"
      :organizationId="selectedOrganizationId"
      :team-member="selectedTeamMember"
      @refresh="loadOrganizations"
      @close="openModal = null"
    />
  </CommonShell>
</template>
