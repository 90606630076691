<script setup lang="ts">
import moment from 'moment'
import { computed } from 'vue'

import { useAccountStore } from '@/stores/account'
import { useUnreadMessageStore } from '@/stores/unread-message'

import type { SectionFullModel } from '@/types/api/models/section.full'
import type { SectionMessageFullModel } from '@/types/api/models/section-message.full'


const props = defineProps<{
  /**
  * Array of messages as returned by backend (i.e. ordered by createdAt, DESC)
  */
  messages: SectionMessageFullModel[]
  selectedSectionId: string
  sections: SectionFullModel[]
}>()

defineEmits<{
  (e: 'select-section', id: string): void
}>()

const unreadMessageStore = useUnreadMessageStore()
const accountStore = useAccountStore()
const sortedSections = (sections: SectionFullModel[], latestMessageBySection: Record<string, SectionMessageFullModel>) => {
  const userSections = sections.filter(s => s.staff.some(staff => staff.id === accountStore.user?.id) || s.organization?.team?.some(member => member.user_id === accountStore.user?.id && ['admin', 'manager'].includes(member.role)))
  return userSections.sort((a, b) => {
    const latestA = latestMessageBySection[a.id]
    const latestB = latestMessageBySection[b.id]
    if (latestA && latestB) {
      return latestB.createdAt.getTime() - latestA.createdAt.getTime()
    } else if (!latestA && !latestB) {
      return a.name.localeCompare(b.name)
    } else if (!latestA) {
      return 1
    } else if (!latestB) {
      return -1
    } else {
      console.error('This should not happen')
      return 0
    }
  });
}

const latestMessageBySection = computed(() => {
  return props.messages.reduce((acc, m) => {
    if (!acc[m.sectionId] || acc[m.sectionId].createdAt < m.createdAt) {
      acc[m.sectionId] = m
    }
    return acc
  }, {} as Record<string, SectionMessageFullModel>)
})
</script>

<template>
  <div class="w-full md:w-96 md:max-h-full scrollbar">
    <h2 class="border-b border-zinc-500 px-3 pb-2 pt-5">Sections</h2>
    <div
      v-for="s in sortedSections(sections, latestMessageBySection)"
      :key="s.id"
      @click="$emit('select-section', s.id)"
      class="flex flex-row cursor-pointer"
      :class="`${selectedSectionId === s.id ? 'bg-dark-lt' : ''} ${s.staff.some(staff => staff.id === accountStore.user?.id) ? (unreadMessageStore.getSectionMessages(s.id).length > 0 ? 'font-bold' : '') : 'opacity-50'}`"
    >
      <div class="flex flex-col justify-center p-3 border-b border-zinc-500 w-full">
        <p class="leading-none">{{ s.name }}</p>
        <p class="leading-none">{{ s.organization?.name }}</p>
        <p class="text-sm text-gray-300">
          {{ latestMessageBySection[s.id]?.createdAt ? moment(latestMessageBySection[s.id]?.createdAt).fromNow() + ' - ' : '' }}
          {{ latestMessageBySection[s.id]?.text }}
        </p>
      </div>

      <div
        v-if="unreadMessageStore.getSectionMessages(s.id).length > 0"
        class="flex flex-col justify-center ml-auto mr-2"
      >
        <span
          class="h-8 w-8 bg-white text-black text-center leading-8 rounded-full"
        >
          {{ unreadMessageStore.getSectionMessages(s.id).length }}
        </span>
      </div>
    </div>
  </div>
</template>
