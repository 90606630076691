<script setup lang="ts">
import { ref, computed } from 'vue'
import * as yup from 'yup'

import type { SectionBaseModel } from '@/types/api/models/section.base'
import type { OrganizationFullModel } from '@/types/api/models/organization.full';


const props = defineProps<{ organizations: OrganizationFullModel[] }>()
const emit = defineEmits<{
  (e: 'new-section', data: SectionBaseModel): void
  (e: 'cancel'): void
}>()

const sectionSchema = yup.object({
  organizationId: yup.string().required(),
  name: yup.string().trim().required().min(2),
  ownerId: yup.string().required(),
})

const selectedOrgId = ref<string | null>(props.organizations.length === 1 ? props.organizations[0].id : null)

const onOrgChange = (e: Event & { target: HTMLSelectElement }) => {
  selectedOrgId.value = e.target.value
  selectedStaffIds.value.clear()
}

const team = computed(() => {
  const orgs = props.organizations
  const orgId = selectedOrgId.value
  if (!orgs.length || !orgId) {
    return null
  }
  return orgs.find(o => o.id === selectedOrgId.value)?.team
})

const selectedStaffIds = ref(new Set<string>())

const onStaffChange = (e: Event & { target: HTMLSelectElement }) => {
  selectedStaffIds.value.add(e.target.value)
}

const selectedStaff = computed(
  () => team.value?.filter(u => selectedStaffIds.value.has(u.id))
)

const removeStaff = (id: string) => selectedStaffIds.value.delete(id)

const onSubmit = (data: {
  organizationId: string
  name: string
  ownerId: string
}) => {
  emit('new-section', {
    ...data,
    staff: Array.from(selectedStaffIds.value).map(id => ({ id })),
  })
}
</script>

<template>
  <Form class="flex flex-col items-start" @submit="onSubmit" :validation-schema="sectionSchema">
    <div class="mb-2 flex flex-col w-full">
      <label for="organizationId">Workspace</label>
      <Field
        name="organizationId"
        as="select"
        class="bg-form-ctrl"
        @change="onOrgChange"
        :value="selectedOrgId"
      >
        <option
          v-for="organization of organizations"
          :value="organization.id"
          :key="organization.id"
        >
          {{ organization.name }}
        </option>
      </Field>
      <ErrorMessage name="organizationId" />
    </div>

    <div class="mb-2 flex flex-col w-full">
      <label for="name">Name</label>
      <Field name="name" type="text" class="bg-form-ctrl" />
      <ErrorMessage name="name" />
    </div>

    <div class="mb-2 flex flex-col w-full">
      <label for="ownerId">Owner</label>
      <Field name="ownerId" as="select" class="bg-form-ctrl">
        <option v-for="teamMember of team" :value="teamMember.id" :key="teamMember.id">
          {{ teamMember?.name }}
        </option>
      </Field>
      <ErrorMessage name="ownerId" />
    </div>

    <div class="mb-2 flex flex-col w-full">
      <div class="mb-2 flex flex-col w-full">
        <label for="staff">Member</label>
        <Field name="staff" as="select" class="bg-form-ctrl w-full" @change="onStaffChange">
          <option v-for="teamMember of team" :value="teamMember.id" :key="teamMember.id">
            {{ teamMember?.name }}
          </option>
        </Field>
        <ErrorMessage name="staff" />
      </div>

      <div class="flex-col w-full divide-y divide-white leading-10">
        <div
          class="flex"
          v-for="staffMember of selectedStaff"
          :key="staffMember.id"
        >
          {{ staffMember.name }}
          <button
            class="ml-auto"
            type="button"
            @click="removeStaff(staffMember.id)"
          >
            <font-awesome-icon :icon="['fas', 'times']" />
          </button>
        </div>
      </div>
    </div>

    <div class="mt-2">
      <button type="submit" class="btn-primary mr-2">Submit</button>
      <button type="button" class="btn-plain" @click="$emit('cancel')">
        Cancel
      </button>
    </div>
  </Form>
</template>
