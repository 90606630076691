<script setup lang="ts">
import BasicDialog from '@/components/BasicDialog.vue'

import { useApi } from '@/services/api'

import { useNotificationStore } from '@/stores/notification'

import type { OrganizationTeamMemberFullModel } from '@/types/api/models/organization-team-member.full'
import { roles, roleToString } from '@/types/api/raw/organization-team-member.full'

import { isLeft } from '@/utils/either'


type Role = OrganizationTeamMemberFullModel['role']



const props = defineProps<{
  isOpen: boolean
  organizationId: string
  teamMember: OrganizationTeamMemberFullModel
}>()

const emit = defineEmits<{ (e: 'close'): void, (e: 'refresh'): void }>()

const { organization: organizationSvc } = useApi()
const notificationStore = useNotificationStore()

const changeRole = (e: Event) => {
  const role = (e.target as HTMLSelectElement).value as Role
  const oId = props.organizationId
  const uId = props.teamMember.id
  organizationSvc.updateTeamMember(oId, uId, { role })
    .then(res => {
      if (isLeft(res)) {
        notificationStore.add({ error: true })
      }
    })
    .catch(err => {
      notificationStore.add({ error: true, message: err })
    })
}

const removeTeamMember = () => {
  organizationSvc.removeTeamMember(props.organizationId, props.teamMember.id)
    .then(res => {
      if (isLeft(res)) {
        notificationStore.add({ error: true })
        return;
      }
      emit('close')
      emit('refresh')
    })
    .catch(err => {
      notificationStore.add({ error: true, message: err })
    })
}
</script>

<template>
  <BasicDialog :is-open="isOpen" @close="$emit('close')">
    <template #title>Team member</template>

    <template #main>
      <div class="flex justify-end">
        <button class="text-red-500" @click="removeTeamMember">
          Remove
        </button>
      </div>
      <div class="flex flex-col gap-2">
        <div class="mx-auto my-5 p-8 rounded-md border-2 border-zinc-200">
          <font-awesome-icon
            :icon="['fas', 'user']"
            class="text-8xl"
          ></font-awesome-icon>
        </div>

        <div>Name: {{ teamMember.name }}</div>

        <div>Handle: {{ teamMember.handle }}</div>

        <div>
          <label for="role">Role: </label>
          <select
            name="role"
            :value="teamMember.role"
            @change="changeRole"
            class="bg-default"
          >
            <option v-for="(opt, idx) in roles" :key="idx" :value="opt">
              {{ roleToString(opt) }}
            </option>
          </select>
        </div>
      </div>
    </template>
  </BasicDialog>
</template>
