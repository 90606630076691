import { ref } from 'vue'
import { defineStore } from 'pinia'

import { usePersistentStorage } from './util/persistent-storage'


export const useAuthStore = defineStore('authentication', () => {
  const { token: storedToken } = usePersistentStorage()
  const _token = ref<string | null>(null)

  const isAuthenticated = () => !!_token.value

  const setToken = (token: string) => {
    _token.value = token
    storedToken.value = token
  }

  const getToken = () => {
    if (!_token.value) {
      throw new Error('user not authenticated')
    }
    return _token.value
  }

  const clearToken = () => {
    _token.value = null
    storedToken.value = null
  }

  const loadFromStorage = () => {
    _token.value = storedToken.value
  }

  return { isAuthenticated, setToken, getToken, clearToken, token: _token, loadFromStorage }
})
