<script setup lang="ts">
import { ref, watch } from 'vue'
import * as yup from 'yup'
import { format } from 'date-fns'

import BasicDialog from '@/components/BasicDialog.vue'

import type { PrepItemBaseModel } from '@/types/api/models/prep-item.base'
import type { PrepListFullModel } from '@/types/api/models/prep-list.full'
import type { PrepListSettableModel } from '@/types/api/models/prep-list.settable'


const props = defineProps<{ isOpen: boolean; list: PrepListFullModel | null }>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'update', data: PrepListSettableModel): void
  (e: 'new-item', item: PrepItemBaseModel): void
  (e: 'delete-item', id: string): void
}>()

watch(() => props.isOpen, isOpen => {
  if (!isOpen) {
    editing.value = null
    newDueAt.value = ''
    newHandover.value = ''
    addingItem.value = false
  }
})

const prepItemSchema = yup.object({
  text: yup.string().trim().required().min(2),
})

const editing = ref<'dueAt' | 'handover' | 'name' | null>(null)

const cancelEdit = () => editing.value = null

const newName = ref('')

const editName = () => {
  if (!props.list) {
    throw new Error()
  }
  newName.value = props.list.name
  editing.value = 'name'
}

const submitName = () => {
  emit('update', { name: newName.value })
  editing.value = null
}

const newDueAt = ref('')

const editDueAt = () => {
  if (!props.list) {
    throw new Error()
  }
  const current = props.list.dueAt
  const day = ('0' + current.getDate()).slice(-2)
  const month = ('0' + (current.getMonth()+1)).slice(-2)
  const dateStr = `${current.getFullYear()}-${month}-${day}`
  console.log('dateStr:', dateStr)
  newDueAt.value = dateStr
  editing.value = 'dueAt'
}

const submitDueAt = () => {
  const date = newDueAt.value.split('-').map(s => +s)
  const dueAt = new Date(date[0], date[1] - 1, date[2], 23, 59, 59)
  emit('update', { dueAt })
  editing.value = null
}

const newHandover = ref('')

const editHandover = () => {
  if (!props.list) {
    throw new Error()
  }
  newHandover.value = props.list.handover || ''
  editing.value = 'handover'
}

const submitHandover = () => {
  const handover = newHandover.value
  emit('update', { handover })
  editing.value = null
}

const addingItem = ref(false)

const submitNewItem = (data: { text: string }) => {
  emit('new-item', data)
  addingItem.value = false
}

const deleteItem = (id: string) => {
  emit('delete-item', id)
}

const cancelNewItem = () => addingItem.value = false
</script>

<style>
tr {
  cursor: pointer;
}

input[type=checkbox] {
  margin: 0.5em;
}
</style>

<template>
  <BasicDialog :is-open="isOpen" @close="$emit('close')">
    <template #title>
      <PrepListIcon class="mr-2" />
      Edit Preplist
    </template>

    <template #main>
      <template v-if="list">
        <div class="flex flex-col">
          <div class="my-2">
            <!-- editable name -->
            <template v-if="editing === 'name'">
              <input type="text" v-model="newName" class="bg-form-ctrl w-full" />
              <div class="my-2">
                <button type="button" @click="submitName" class="btn-primary mr-2">
                  Save
                </button>
                <button type="button" @click="cancelEdit" class="btn-plain">
                  Cancel
                </button>
              </div>
            </template>
            <template v-else>
              <div class="flex">
                <h2>{{ list.name }}</h2>
                <button type="button" @click="editName" class="ml-auto">
                  <EditIcon />
                </button>
              </div>
            </template>

            <h2>{{ list.section?.name }}</h2>

            <p>{{ list.createdBy?.name }}</p>

            <!-- editable due date -->
            <template v-if="editing === 'dueAt'">
              <input type="date" v-model="newDueAt" class="bg-form-ctrl w-full" />
              <div class="my-2">
                <button type="button" @click="submitDueAt" class="btn-primary mr-2">
                  Save
                </button>
                <button type="button" @click="cancelEdit" class="btn-plain">
                  Cancel
                </button>
              </div>
            </template>
            <template v-else>
              <p class="flex">
                {{ format(list.dueAt, 'dd MMMM yyyy') }}
                <button type="button" @click="editDueAt" class="ml-auto">
                  <EditIcon />
                </button>
              </p>
            </template>
          </div>

          <hr class="solid">

          <div class="my-2">
            <h3>Prep Items</h3>
            <ul>
              <li
                v-for="item in list.prepItems"
                :key="item.id"
                class="flex items-center bg-light my-1 p-2 rounded"
              >
                <span :class="{ 'line-through': item.finished }">
                  {{ item.text }}
                </span>
                <button type="button" @click="deleteItem(item.id)" class="ml-auto">
                  <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
                </button>
              </li>
            </ul>

            <!-- input for new prep-item -->
            <div v-if="addingItem" class="mt-4 mb-2">
              <Form
                class="flex flex-col items-start"
                @submit="submitNewItem"
                :validation-schema="prepItemSchema"
              >
                <Field name="text" type="text" class="w-full bg-form-ctrl" />
                <ErrorMessage name="text" />

                <div class="mt-2">
                  <button type="submit" class="btn-primary mr-2">Submit</button>
                  <button type="button" class="btn-plain" @click="cancelNewItem">
                    Cancel
                  </button>
                </div>
              </Form>
            </div>
            <div v-else class="flex justify-center m-2">
              <button class="btn-accent" type="button" @click="addingItem = true">
                Add Prep Item
              </button>
            </div>
          </div>

          <hr class="solid">

          <div class="my-2">
            <!-- editable handover -->
            <div class="flex">
              <h3>Handover</h3>
              <button
                v-if="editing !== 'handover'"
                class="ml-auto"
                type="button"
                @click="editHandover"
              >
                <EditIcon />
              </button>
            </div>
            <template v-if="editing === 'handover'">
              <textarea
                rows="10"
                v-model="newHandover"
                class="bg-form-ctrl w-full"
              ></textarea>
              <div class="mt-2">
                <button type="button" @click="submitHandover" class="btn-primary mr-2">
                  Save
                </button>
                <button type="button" @click="cancelEdit" class="btn-plain">
                  Cancel
                </button>
              </div>
            </template>
            <template v-else>
              <p>{{ list.handover }}</p>
            </template>
          </div>
        </div>

      </template>

      <template v-else>
        <p>Loading prep-list...</p>
      </template>
    </template>
  </BasicDialog>
</template>
