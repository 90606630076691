import * as Sentry from "@sentry/browser";
import { defineStore } from 'pinia'
import { ref } from 'vue'

import type { UserCommonWithEmailModel } from '@/types/api/models/user.common'

import { usePersistentStorage } from './util/persistent-storage'


export const useAccountStore = defineStore('account', () => {
  const { user: storedUser } = usePersistentStorage()
  const user = ref<UserCommonWithEmailModel | null>(storedUser.value)

  const setUser = (newUser: UserCommonWithEmailModel) => {
    user.value = newUser
    storedUser.value = newUser
    const { id, email } = newUser
    Sentry.setUser({ id, email });
  }

  const clearUser = () => {
    user.value = null
    storedUser.value = null
  }

  return { setUser, clearUser, user }
})
