<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue'


defineProps<{ isOpen: boolean }>()
const emit = defineEmits<{ (e: 'close', data: string): void }>()

const closeModal = (data: any) => {
  emit('close', data)
}
</script>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-white bg-opacity-40" />
      </TransitionChild>

      <div class="fixed inset-0">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel class="max-w-xl min-w-[400px] transform bg-default text-left
            align-middle shadow-xl transition-all rounded-lg">
              <DialogTitle as="h2" class="bg-dark flex px-6 py-4 leading-6 rounded-t-lg">
                <slot name="title"></slot>
                <button class="ml-auto">
                  <font-awesome-icon :icon="['fas', 'times']" @click="closeModal" />
                </button>
              </DialogTitle>

              <div class="px-6 pb-4 pt-2 max-h-[70vh] scrollbar">
                <slot name="main"></slot>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
