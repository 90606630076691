import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { Notification } from '@/types/notification'


export const useNotificationStore = defineStore('notification', () => {
  const _queue: Notification[] = []

  const hasNext = ref(false)

  const add = (notification: Notification) => {
    _queue.push(notification)
    hasNext.value = true
  }

  const getNext = () => {
    if (!_queue.length) {
      hasNext.value = false
      return null
    }
    return _queue[0]
  }

  const dismiss = () => {
    _queue.shift()
    if (!_queue.length) {
      hasNext.value = false
    }
  }

  return { hasNext, add, getNext, dismiss }
})
