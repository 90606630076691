import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPauseCircle,
  faCheckCircle,
  faClipboard,
  faSpinner,
  faUsers,
  faTimesCircle,
  faPlayCircle,
  faPen,
  faUser,
  faTimes,
  faUserCircle,
  faComments,
  faImage,
  faPaperPlane,
  faPaperclip,
  faConciergeBell,
} from '@fortawesome/free-solid-svg-icons'


export const initialize = () => {
  library.add(
    faSpinner,
    faClipboard,
    faUsers,
    faPauseCircle,
    faCheckCircle,
    faTimesCircle,
    faPlayCircle,
    faPen,
    faUser,
    faTimes,
    faUserCircle,
    faComments,
    faImage,
    faPaperPlane,
    faPaperclip,
    faConciergeBell,
  )
}
