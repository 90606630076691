<script setup lang="ts">
import { computed, ref } from 'vue';
import * as yup from 'yup'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

import BasicDialog from '@/components/BasicDialog.vue'
import router from '@/router'
import { useApi } from "@/services/api";
import { useAuthStore } from '@/stores/auth'
import { useAccountStore } from '@/stores/account'
import { useNotificationStore } from '@/stores/notification'
import { useOrganizationStore } from '@/stores/organization'
import { useUnreadMessageStore } from '@/stores/unread-message'
import { isLeft, isRight, unwrapEither } from '@/utils/either';

import type { PatchError } from '@/services/api/user';
import type { OrganizationFullModel } from '@/types/api/models/organization.full';

const activeComponent = computed(() => {
  switch (router.currentRoute.value.name) {
    case 'prep-list-home':
      return 'prep-list-home'
    case 'connection-home':
      return 'connection-home'
    case 'message-home':
      return 'message-home'
    default:
      return null
  }
})

const notificationStore = useNotificationStore()
const organizationStore = useOrganizationStore()
const unreadMessageStore = useUnreadMessageStore()
const components = [
 { name: 'message-home', label: 'Messages', icon: 'comments', badge: getNumRelevantUnread },
 { name: 'connection-home', label: 'Connections', icon: 'users' },
 { name: 'prep-list-home', label: 'Prep lists', icon: 'clipboard' },
]

const { clearToken } = useAuthStore()
const logout = () => {
  clearToken()
  router.push({ name: 'login' })
}

const accountStore = useAccountStore()

const schema = yup.object({
  name: yup.string().trim().required().min(2),
})

const errRes = ref<string | null>(null)
const openModal = ref<'settings' | null>(null)

accountStore.$subscribe(async (_, state) => {
  console.log('accountStore update with state:', state)
  if (state.user) {
    if (state.user?.name === state.user?.email || !state.user?.name) {
      openModal.value = 'settings'
    }
  }
})

const handlePatchError = (error: PatchError) => {
  switch (error) {
    case 'UNKNOWN_ERROR':
      errRes.value = 'Something went wrong.'
      break;
    default:
      console.error(`Unrecognized error passed to login error handler: ${error}`)
  }
}

const onSubmit = (values: {
  name: string;
}) => {
  errRes.value = null

  const { patchAccount } = useApi().user;
  patchAccount({ name: values.name })
    .then(async either => {
      if (isRight(either)) {
        const res = await useApi().user.getAccount()
        if (isLeft(res)) {
          const err = unwrapEither(res)
          return notificationStore.add({ error: true, message: err })
        }
        accountStore.setUser(unwrapEither(res))
        openModal.value = null;
      } else {
        handlePatchError(unwrapEither(either))
      }
    })
}

function onCloseSettings() {
  if (accountStore.user?.name === accountStore.user?.email || !accountStore.user?.name) {
    errRes.value = 'Name is required.'
  } else {
    openModal.value = null    
  }
}

function getNumRelevantUnread() {

  const organizations = organizationStore.organizations as OrganizationFullModel[]
  const sections = organizations.flatMap(org => org.sections)
  const mySections = sections.filter(s => s.staff.some(staff => staff.id === accountStore.user?.id))
  return unreadMessageStore.messages.filter(m => mySections.some(s => s.id === m.sectionId)).length
}
</script>

<template>
  <div class="flex flex-col bg-dark">
    <div class="flex bg-default p-2">
      <img class="hidden md:block h-12 mr-8" src="@/assets/images/logo.png">

      <img class="block md:hidden h-12 mr-8" src="@/assets/images/logo-sm.svg">

      <nav class="flex justify-center">
        <router-link
          v-for="comp in components" :key="comp.name"
          :to="{ name: comp.name }"
          :class="{ active: activeComponent === comp.name }"
          class="text-xl mr-2 p-2 rounded flex items-center"
          :active-class="'nav-link-active'"
        >
          <font-awesome-icon :icon="['fas', comp.icon]" class="mb-1" />
          <span class="hidden md:inline-block ml-2">
            {{ comp.label }}
          </span>
          <span v-if="comp.badge?.()" class="ml-2 bg-red-500 text-white rounded-full px-2">
            {{ comp.badge() }}
          </span>
        </router-link>
      </nav>

      <Popover class="flex justify-center ml-auto mr-7">
        <PopoverButton>
          <font-awesome-icon :icon="['fas', 'user-circle']" class="text-2xl" />
          <span class="hidden md:inline-block ml-2 text-2xl font-bold">
            {{ accountStore.user?.name || 'no user' }}
          </span>
        </PopoverButton>

        <transition
          enter-active-class="transition duration-200 ease-out"
          enter-from-class="translate-y-1 opacity-0"
          enter-to-class="translate-y-0 opacity-100"
          leave-active-class="transition duration-150 ease-in"
          leave-from-class="translate-y-0 opacity-100"
          leave-to-class="translate-y-1 opacity-0"
        >
          <PopoverPanel
            class="absolute top-16 right-1 z-10 rounded-lg border-2 border-zinc-200 p-10 bg-default"
          >
            <button type="button" @click="logout" class="ml-auto">Logout</button>
          </PopoverPanel>
        </transition>
      </Popover>
    </div>

    <div class="flex flex-col flex-1 p-2">
      <slot></slot>
    </div>

    <BasicDialog :is-open="openModal === 'settings'" @close="onCloseSettings">
      <template #title>
        Settings
      </template>

      <template #main>
        <Form class="flex flex-col items-start" @submit="onSubmit" :validation-schema="schema">
          <label for="name">Name</label>
          <Field name="name" type="text" class="bg-form-ctrl" />
          <ErrorMessage name="name" />
          <button type="submit" class="btn-primary my-4">Save</button>
        </Form>
        <div v-if="errRes">{{ errRes }}</div>
      </template>
    </BasicDialog>

  </div>
</template>
