<script setup lang="ts">
import { ref, watch } from 'vue';
import { RouterView } from 'vue-router'
import { useRegisterSW } from 'virtual:pwa-register/vue'

import PWAInstallPrompt from '@/components/PWAInstallPrompt.vue'
import NotificationPopover from '@/components/NotificationPopover.vue'

import { useApi } from '@/services/api'
import { useWs } from '@/services/ws'

import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from './stores/notification'
import { useProgressStore } from '@/stores/progress'
import { useAccountStore } from '@/stores/account'
import { useUnreadMessageStore } from '@/stores/unread-message'

import type { BeforeInstallPromptEvent } from '@/types/before-install-prompt.event'

import { isLeft, unwrapEither } from '@/utils/either'


const showInstallPrompt = ref(false)
let deferredPrompt: BeforeInstallPromptEvent

window.addEventListener('beforeinstallprompt', e => {
  e.preventDefault()
  deferredPrompt = e as BeforeInstallPromptEvent
  showInstallPrompt.value = true
  console.log('"beforeinstallprompt" event fired')
})

const promptUpdateSW = ref(false)

const { updateServiceWorker } = useRegisterSW({
  onNeedRefresh() {
    console.log('onNeedRefresh called')
    promptUpdateSW.value = true
  },
  onOfflineReady() {
    console.log('onOfflineReady called')
  },
  onRegisteredSW() {
    console.log('onRegisteredSW called')
  },
  onRegisterError(...args) {
    console.log('onRegisterError called', args)
  },
})

const authStore = useAuthStore()
const notificationStore = useNotificationStore()
const progressStore = useProgressStore()
const { setUser, clearUser } = useAccountStore()
const unreadMessageStore = useUnreadMessageStore()

const { user: userApi } = useApi()
const { sectionMessage: sectionMessageWs } = useWs()

authStore.$subscribe(async (_, state) => {
  console.log('authStore update with state:', state)
  if (!state.token) {
    sectionMessageWs.disconnect()
    clearUser()
    return;
  }
  console.log('connecting WS')
  sectionMessageWs.connect()
  const res = await userApi.getAccount()
  if (isLeft(res)) {
    const err = unwrapEither(res)
    return notificationStore.add({ error: true, message: err })
  }
  setUser(unwrapEither(res))
})

watch(sectionMessageWs.newMessages, newMessages => {
  console.log('new messages:', newMessages)
  unreadMessageStore.addMessages(newMessages)
})

authStore.loadFromStorage()
</script>

<template>
  <div v-if="promptUpdateSW">
    <button type="button" @click="() => updateServiceWorker()">
      Update app
    </button>
  </div>

  <RouterView class="min-h-screen" />

  <div
    v-if="progressStore.waiting"
    class="fixed top-0 left-0 z-[100] bg-slate-400 opacity-60 h-full w-full flex justify-center items-center"
  >
    <font-awesome-icon class="text-9xl" :icon="['fas', 'spinner']" spin />
  </div>

  <PWAInstallPrompt
    v-if="showInstallPrompt"
    :deferredPrompt="deferredPrompt"
    class="fixed bottom-10 left-1/2 -translate-x-1/2 z-10"
  />

  <!-- <NotificationPopover class="fixed bottom-10 left-1/2 -translate-x-1/2 z-20" /> -->
</template>
