import { useAuthStore } from '@/stores/auth'
import { useProgressStore } from '@/stores/progress'

import type { Either } from '@/types/either'
import type { SectionBase } from '@/types/api/raw/section.base'
import type { SectionFull } from '@/types/api/raw/section.full'
import type { SectionMessageFull } from '@/types/api/raw/section-message.full'

import type { SectionBaseModel } from '@/types/api/models/section.base'
import type { SectionFullModel } from '@/types/api/models/section.full'
import type { SectionMessageFullModel } from '@/types/api/models/section-message.full'

import { makeLeft, makeRight } from '@/utils/either'

import { useHttp } from '../util/http'
import { useSerialization } from '../util/serialization'



type GetSectionsError = 'UNKNOWN_ERROR'
type CreateSectionError = 'UNKNOWN_ERROR'
type DeleteSectionError = 'UNKNOWN_ERROR'
type AddStaffMemberError = 'UNKNOWN_ERROR'
type RemoveStaffMemberError = 'UNKNOWN_ERROR'
type GetMessagesError = 'UNKNOWN_ERROR'


const translateGetListError = (message: string): GetSectionsError => {
  switch (message) {
    default:
      return 'UNKNOWN_ERROR'
  }
}

const translateCreateError = (message: string): CreateSectionError => {
  switch (message) {
    default:
      return 'UNKNOWN_ERROR'
  }
}

const translateDeleteError = (message: string): DeleteSectionError => {
  switch (message) {
    default:
      return 'UNKNOWN_ERROR'
  }
}

const translateAddStaffMemberError = (message: string): AddStaffMemberError => {
  switch (message) {
    default:
      return 'UNKNOWN_ERROR'
  }
}

const translateRemoveStaffMemberError = (message: string): RemoveStaffMemberError => {
  switch (message) {
    default:
      return 'UNKNOWN_ERROR'
  }
}

const translateGetMessagesError = (message: string): GetSectionsError => {
  switch (message) {
    default:
      return 'UNKNOWN_ERROR'
  }
}


const { post, delete: doDelete, get } = useHttp()

const srlz = useSerialization<SectionFull, SectionFullModel>()
const { serializeRecord, serializeParams } = srlz.makeSerializer()
const { deserializeArray } = srlz.makeDeserializer()

const cfg = {
  rawToModel: {
    values: {
      created_at: (v: string) => new Date(v),
    },
  },
  modelToRaw: {
    values: { createdAt: (v: Date) => v.toISOString() },
  },
}
const msgSrlz = useSerialization<SectionMessageFull, SectionMessageFullModel>(cfg)
const messagesDeserializer = msgSrlz.makeDeserializer()


export const useSection = (route: string) => {
  const { getToken } = useAuthStore()
  const { track } = useProgressStore()

  const getList = async (params?: {
    include?: ('organization' | 'owner' | 'staff' | 'organization.team')[]
  }): Promise<Either<GetSectionsError, SectionFullModel[]>> => {
    try {
      const p = serializeParams(params)
      const res = await track(get<SectionFull[]>(route, p, getToken()))
      if ('statusCode' in res) {
        return makeLeft(translateGetListError(res.message))
      }
      return makeRight(deserializeArray(res))
    } catch (err) {
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  const create = async (
    data: SectionBaseModel,
  ): Promise<Either<CreateSectionError, SectionFull>> => {
    try {
      const p = serializeRecord(data)
      const t = getToken()
      const res = await track(post<SectionBase, SectionFull>(route, p, t))
      if ('statusCode' in res) {
        return makeLeft(translateCreateError(res.message))
      }
      return makeRight(res)
    } catch (err) {
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  const deleteSection = async (
    id: string,
  ): Promise<Either<DeleteSectionError, 'ok'>> => {
    try {
      const res = await track(doDelete<{}>(`${route}/${id}`, getToken()))
      console.log('res:', res)
      if ('statusCode' in res) {
        return makeLeft(translateDeleteError(res.message))
      }
      return makeRight('ok')
    } catch (err) {
      console.log('err:', err)
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  const addStaffMember = async (
    id: string,
    userId: string,
  ): Promise<Either<AddStaffMemberError, 'ok'>> => {
    try {
      const p = serializeRecord({ id: userId })
      const url = `${route}/${id}/staff`
      const res = await track(post<{ id: string }, {}>(url, p, getToken()))
      if ('statusCode' in res) {
        return makeLeft(translateAddStaffMemberError(res.message))
      }
      return makeRight('ok')
    } catch (err) {
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  const removeStaffMember = async (
    id: string,
    userId: string,
  ): Promise<Either<RemoveStaffMemberError, 'ok'>> => {
    try {
      const url = `${route}/${id}/staff/${userId}`
      const res = await track(doDelete<{}>(url, getToken()))
      if ('statusCode' in res) {
        return makeLeft(translateRemoveStaffMemberError(res.message))
      }
      return makeRight('ok')
    } catch (err) {
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  const getMessages = async (
    id: string,
    params: {
      include?: ('author' | 'viewedBy')[]
      before?: Date
      limit?: number
    } = {},
  ): Promise<Either<GetMessagesError, SectionMessageFullModel[]>> => {
    try {
      const { include, before, limit } = params
      // TODO: Hack. Fix this.
      let p: any = serializeParams({ include })
      if (before) {
        p = { ...p, filter: { _before: before.toISOString() } }
      }
      if (limit) {
        p = { ...p, filter: { ...p.filter, _limit: limit } }
      }
      const url = `${route}/${id}/message`
      const res = await track(get<SectionMessageFull[]>(url, p, getToken()))
      if ('statusCode' in res) {
        return makeLeft(translateGetMessagesError(res.message))
      }
      return makeRight(messagesDeserializer.deserializeArray(res))
    } catch (err) {
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  return { create, deleteSection, addStaffMember, removeStaffMember, getList, getMessages }
}
