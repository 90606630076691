import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

import { useApi } from "@/services/api";

const firebaseConfig = {
    apiKey: "AIzaSyCM7TMOxNdsmBMLI-01KVxH1f3Im6CO5K0",
    authDomain: "reference-tide-371309.firebaseapp.com",
    projectId: "reference-tide-371309",
    storageBucket: "reference-tide-371309.appspot.com",
    messagingSenderId: "698343719090",
    appId: "1:698343719090:web:cfa115bdd22cb2545a09ab"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

function requestPermission() {
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            registerPushNotifications();
        }
    });
}
export function registerPushNotifications() {
    isSupported().then((isSupported) => {
        if (!isSupported) {
            console.warn('Notifications are not supported');
        } else {
            const messaging = getMessaging(app);
            getToken(messaging, { vapidKey: "BEfBi_kDGoLr9EJy-IFdUk99AAYQY2GBakPbtEW1jMT5G1fLBK2q4b8oxu6551AXua5fMrb4hIH0w-Akay8V8Dk" }).then((currentToken) => {
                if (currentToken) {
                    // Send the token to your server and update the UI if necessary
                    // ...
                    console.log('currentToken:', currentToken);
                    const { patchAccount } = useApi().user;
                    patchAccount({ fcm_token: currentToken });
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                    requestPermission();
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // ...
            });
        }
    });
}
