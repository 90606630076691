<script setup lang="ts">
import type { BeforeInstallPromptEvent } from '@/types/before-install-prompt.event'

const props = defineProps<{
  deferredPrompt?: BeforeInstallPromptEvent;
}>()

const triggerPrompt = () => {
  if (!props.deferredPrompt) {
    console.log('no prompt method available')
    return;
  }
  props.deferredPrompt.prompt()
    .then(({ userChoice }) => console.log(`installation ${userChoice}`))
    .catch(err => console.error(err))
}
</script>

<template>
  <button class="btn-accent" type="button" @click="triggerPrompt">
    Install app
  </button>
</template>
