import { defineStore } from 'pinia'

import { useApi } from '@/services/api'
import { useNotificationStore } from '@/stores/notification'
import type { OrganizationFullModel } from '@/types/api/models/organization.full'
import { isLeft, unwrapEither } from '@/utils/either'


export const useOrganizationStore = defineStore({
  id: 'organization',
  state: () => ({
    organizations: [] as OrganizationFullModel[]
  }),
  actions: {
    async fetchOrganizations() {
        const { organization: organizationSvc } = useApi()
        await organizationSvc
        .getList({
          include: ['sections.owner', 'sections.staff', 'team']
        })
        .then((either) => {
          if (isLeft(either)) {
            const notificationStore = useNotificationStore()
            return notificationStore.add({ error: true })
          }
          const orgs = unwrapEither(either)
          this.organizations = orgs
        })
    }
  }
})

setTimeout(() => useOrganizationStore().fetchOrganizations(), 0)
