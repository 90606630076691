<script setup lang="ts">
import { ref, watch } from 'vue'
import * as yup from 'yup'
import { format, formatDistanceToNow } from 'date-fns'

import BasicDialog from '@/components/BasicDialog.vue'

import type { PrepItemBaseModel } from '@/types/api/models/prep-item.base'
import type { PrepListFullModel } from '@/types/api/models/prep-list.full'


const props = defineProps<{ isOpen: boolean; list: PrepListFullModel | null }>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'update-item', data: { id: string; finished: boolean }): void
  (e: 'new-item', item: PrepItemBaseModel): void
}>()

watch(() => props.isOpen, isOpen => {
  if (!isOpen) {
    addingPrepItem.value = false
    return;
  }
})

const prepItemSchema = yup.object({
  text: yup.string().trim().required().min(2),
})

const toggleFinished = (el: EventTarget | null, id: string) => {
  if (!el) {
    return
  }
  const finished = (el as HTMLInputElement).checked
  emit('update-item', { id, finished })
}

const addingPrepItem = ref(false)

const submitNewPrepItem = (data: { text: string }) => {
  emit('new-item', data)
  addingPrepItem.value = false
}

const cancelNewPrepItem = () => addingPrepItem.value = false
</script>

<style>
tr {
  cursor: pointer;
}

input[type=checkbox] {
  margin: 0.5em;
}
</style>

<template>
  <BasicDialog :is-open="isOpen" @close="$emit('close')">
    <template #title>
      <PrepListIcon class="mr-2" />
      {{ list?.name || 'Loading...' }}
    </template>

    <template #main>
      <template v-if="list">
        <div class="flex flex-col">
          <div class="my-2">
            <p>{{ format(list.dueAt, 'dd MMMM yyyy') }}</p>

            <h2>{{ list.section?.name }}</h2>

            <p>{{ list.createdBy?.name }}</p>
          </div>

          <hr class="solid">

          <!-- prep-items with checkboxes -->
          <div class="my-2">
            <h3>Prep Items</h3>
            <ul>
              <li
                v-for="item in list.prepItems"
                :key="item.id"
                @change="(event) => toggleFinished(event.target, item.id)"
                >
                <input
                  v-if="list.published"
                  type="checkbox"
                  :checked="item.finished"
                />
                <span>{{ item.text }}</span>
                <span v-if="item.finished && item.finishedAt && item.finishedBy" class="text-green-500"> - {{ item.finishedBy?.name }} {{ new Intl.DateTimeFormat(undefined, {timeStyle: 'short'}).format(new Date(item.finishedAt)) }}</span>
              </li>
            </ul>

            <!-- input for new prep-item -->
            <div v-if="addingPrepItem" class="mt-4 mb-2">
              <Form class="flex flex-col items-start"
                @submit="submitNewPrepItem"
                :validation-schema="prepItemSchema"
                >
                <Field name="text" type="text" class="bg-form-ctrl w-full" />
                <ErrorMessage name="text" />

                <div class="mt-2">
                  <button type="submit" class="btn-primary mr-2">Submit</button>
                  <button type="button" class="btn-plain" @click="cancelNewPrepItem">
                    Cancel
                  </button>
                </div>
              </Form>
            </div>
            <div v-else class="flex justify-center m-2">
              <button type="button" @click="addingPrepItem = true" class="btn-accent">
                Add Prep Item
              </button>
            </div>
          </div>

          <hr class="solid">

          <div class="my-2">
            <h3>Handover</h3>
            <p>{{ list.handover }}</p>
          </div>
        </div>
      </template>

      <template v-else>
        <p>Loading prep-list...</p>
      </template>
    </template>
  </BasicDialog>
</template>
