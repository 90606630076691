import { useAuthStore } from '@/stores/auth'
import { useProgressStore } from '@/stores/progress'

import type { Either } from '@/types/either'
import type { UserCommon, UserCommonWithEmail } from '@/types/api/raw/user.common'
import type { UserCommonModel, UserCommonWithEmailModel } from '@/types/api/models/user.common'

import { makeLeft, makeRight } from '@/utils/either'

import { useHttp } from '../util/http'
import { useSerialization } from '../util/serialization'



type FindUserError = 'UNKNOWN_ERROR'
type GetAccountError = 'UNKNOWN_ERROR'
type PatchAccountError = 'UNKNOWN_ERROR'


const translateFindError = (message: string): FindUserError => {
  switch (message) {
    default:
      return 'UNKNOWN_ERROR'
  }
}

const translateGetAccountError = (message: string): GetAccountError => {
  switch (message) {
    default:
      return 'UNKNOWN_ERROR'
  }
}

const translatePatchAccountError = (message: string): PatchAccountError => {
  switch (message) {
    default:
      return 'UNKNOWN_ERROR'
  }
}

const { get, patch } = useHttp()

const userSrlz = useSerialization<UserCommon, UserCommonModel>()
const userWithEmailSrlz = useSerialization<UserCommonWithEmail, UserCommonWithEmailModel>()

const { makeDeserializer } = userSrlz
const { makeDeserializer: makeDeserializerWithEmail } = userWithEmailSrlz
const { deserializeArray, deserializeRecord } = makeDeserializer()
const { deserializeRecord: deserializeRecordWithEmail } = makeDeserializerWithEmail()

export type PatchError = 'UNKNOWN_ERROR'

export const useUser = (route: string) => {
  const { getToken } = useAuthStore()
  const { track } = useProgressStore()

  const find = async (
    params: { email: string } | { handle: string },
  ): Promise<Either<FindUserError, UserCommonModel[]>> => {
    try {
      const p = { filter: params }
      const res = await track(get<UserCommon[]>(route, p, getToken()))
      if ('statusCode' in res) {
        return makeLeft(translateFindError(res.message))
      }
      return makeRight(deserializeArray(res))
    } catch (err) {
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  const getAccount = async () => {
    try {
      const res = await track(get<UserCommonWithEmail>(`${route}/me`, null, getToken()))
      if ('statusCode' in res) {
        return makeLeft(translateGetAccountError(res.message))
      }
      return makeRight(deserializeRecordWithEmail(res))
    } catch (err) {
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  const patchAccount = async (data: Partial<UserCommon>): Promise<Either<PatchError, UserCommonModel>> => {
    try {
      const res = await track(
        patch<Partial<UserCommon>, UserCommon>(`${route}/me`, data, getToken()),
      )
      if ('statusCode' in res) {
        return makeLeft(translatePatchAccountError(res.message))
      }
      return makeRight(deserializeRecord(res))
    } catch (err) {
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  return { find, getAccount, patchAccount }
}
