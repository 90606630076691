<script setup lang="ts">
import { ref, shallowRef } from 'vue';
import * as yup from 'yup'

import { useApi } from '@/services/api';
import { useAccountStore } from '@/stores/account';
import { useNotificationStore } from '@/stores/notification';
import type { OrganizationFullModel } from '@/types/api/models/organization.full';
import type { OrganizationTeamMemberFull } from '@/types/api/raw/organization-team-member.full';
import { roles, roleToString } from '@/types/api/raw/organization-team-member.full'
import { isLeft, unwrapEither } from '@/utils/either';

type Role = OrganizationTeamMemberFull['role']
type SectionValue = { orgId: string, sectionId: string }

const NEW_SECTION = 'new section'
const NO_SECTION = 'no section'

const { section: sectionSvc } = useApi()
const accountStore = useAccountStore()
const notificationStore = useNotificationStore()

const props = defineProps<{ organizations: OrganizationFullModel[] }>()
const emit = defineEmits<{
  (e: 'new-team-members', data: { organizationId: string; sectionIds: string[]; role: Role; memberIds: string[]; emails: string[], callback: Function }): void
  (e: 'close'): void
}>()

const sortedOrgs = props.organizations.slice().sort((a, b) => b.sections.length - a.sections.length)
const sectionOptions = sortedOrgs.map(org => ({
  id: org.id,
  label: org.name,
  options: [...getSortedSections(org.sections).map(section => ({
    label: section.name,
    value: { orgId: org.id, sectionId: section.id },
  })),
  { label: 'New section', value: { orgId: org.id, sectionId: NEW_SECTION } },
  { label: 'No section (add to org only)', value: { orgId: org.id, sectionId: NO_SECTION } },
  ],
}))
const selectedSection = shallowRef<SectionValue | null>(sortedOrgs.length === 1 && sortedOrgs[0].sections.length === 0 ? sectionOptions[0].options[0].value : null)
const selectedRole = ref<Role>('team_member')

const teamSchema = yup.object({
  section: yup.object().shape({
    orgId: yup.string().required(),
    sectionId: yup.string().required(),
  }).required(),
  sectionName: yup.string().test(function (value) {
    if (this.parent.section?.sectionId === NEW_SECTION) {
      return yup.string().trim().required().min(2).isValidSync(value)
    }
    return true
  }),
  role: yup.string().required(),
  memberIds: yup.mixed().test(function (value) {
    if (this.parent.emails?.trim()) {
      return true
    }
    if (Array.isArray(value)) {
      if (value?.length === 0) {
        return this.createError({ message: 'At least one member or email is required' })
      }
      if (value.every(v => yup.string().required().uuid().isValidSync(v))) {
        return true
      } else {
        return this.createError({ message: `Invalid member ID(s): ${value.filter(v => !yup.string().required().uuid().isValidSync(v)).join(', ')}` })
      }
    } else if (typeof value === 'string') {
      if (yup.string().required().uuid().isValidSync(value)) {
        return true
      } else {
        return this.createError({ message: 'Invalid member ID' })
      }
    } else {
      return this.createError({ message: 'At least one member or email is required' })
    }
  }),
  emails: yup.string().test(function (value) {
    if (this.parent.memberIds?.length) {
      return true
    }
    if (value) {
      const values = value.split('\n').map(e => e.trim()).filter(e => e.length)
      if (values.every(e => yup.string().email().isValidSync(e))) {
        return true
      } else {
        return this.createError({ message: `Invalid email(s): ${values.filter(e => !yup.string().email().isValidSync(e)).join(', ')}` })
      }
    }
    return this.createError({ message: 'At least one member or email is required' })
  }),
})

const onSubmit = async (data: {
  section: SectionValue
  sectionName: string
  role: Role
  memberIds: string[]
  emails: string
}, { resetForm, evt }: { resetForm: Function, evt: Event & { submitter: HTMLButtonElement } }) => {
  const dataset = evt.submitter.dataset
  const sectionIds = await getSectionIds()
  emit('new-team-members', {
    organizationId: data.section.orgId,
    sectionIds: sectionIds,
    role: data.role,
    memberIds: typeof data.memberIds === 'string' ? [data.memberIds] : data.memberIds ?? [],
    emails: data.emails?.split('\n').map(e => e.trim()).filter(e => e.length) ?? [],
    callback: dataset.reset ? resetForm : () => emit('close'),
  })
  async function getSectionIds() {
    if (data.section.sectionId === NEW_SECTION) {
      const ownerId = accountStore.user!.id
      return sectionSvc.create({ organizationId: data.section.orgId, name: data.sectionName, ownerId })
        .then(either => {
          if (isLeft(either)) {
            notificationStore.add({ error: true })
            throw new Error('Failed to create section')
          }
          return [unwrapEither(either).id]
        })
        .catch(err => {
          notificationStore.add({ error: true, message: err })
          throw err
        })
    }
    if (data.section.sectionId === NO_SECTION) {
      return []
    }
    return [data.section.sectionId]
  }
}

function getSortedSections(sections: OrganizationFullModel['sections']) {
  return sections.slice().sort((a, b) => a.name.localeCompare(b.name))
}

function getMembers(orgId?: string) {
  const org = props.organizations.find(org => org.id === orgId)
  if (org) {
    return org.team.map(m => ({
      label: m.name,
      value: m.id,
    }))
  }
  return []
}

function isInSection(section: SectionValue | null, memberId: string) {
  if (!section) {
    return false
  }
  const org = props.organizations.find(org => org.id === section.orgId)
  if (!org) {
    return false
  }
  const sectionMembers = org.sections.find(s => s.id === section.sectionId)?.staff
  return !!sectionMembers?.find(m => m.id === memberId)
}

function onSectionChange(setFieldValue: Function, e: Event) {
  const target = e.target as HTMLSelectElement
  const selectedOption = target.options[target.selectedIndex] as HTMLOptionElement & { _value: SectionValue }
  const value = selectedOption._value // TODO: figure out why _value is necessary
  const memberIds = getMembers(value.orgId).map(m => m.value).filter(m => isInSection(value, m))
  setFieldValue('memberIds', memberIds)
}
</script>

<template>
  <Form class="flex flex-col items-start" @submit="onSubmit" :validation-schema="teamSchema" v-slot="{ setFieldValue }">
    <div class="mb-2 flex flex-col w-full">
      <label for="role">Role</label>
      <Field name="role" as="select" class="bg-form-ctrl" v-model="selectedRole">
        <option v-for="role of roles" :value="role" :key="role">
          <!-- TODO: admin option only for admins-->
          {{ roleToString(role) }}
        </option>
      </Field>
      <ErrorMessage name="role" />
    </div>
    <div class="mb-2 flex flex-col w-full">
      <label for="section">Section</label>
      <Field name="section" as="select" class="bg-form-ctrl h-full" v-model="selectedSection"
        @change="onSectionChange(setFieldValue, $event)">
        <optgroup v-for="org of sectionOptions" :key="org.id" :label="org.label">
          <option v-for="section of org.options" :value="section.value" :key="JSON.stringify(section.value)">
            {{ section.label }}
          </option>
        </optgroup>
      </Field>
      <Field name="sectionName" v-if="selectedSection?.sectionId === NEW_SECTION" class="bg-form-ctrl m-2"
        placeholder="Section name" />
      <ErrorMessage name="sectionId" />
    </div>
    <!-- TODO: new section's owner -->
    <div class="mb-2 flex flex-col w-full" v-if="selectedSection?.sectionId !== NO_SECTION">
      Org Members
      <div v-for="member of getMembers(selectedSection?.orgId)" :key="member.value" class="flex items-center">
        <Field name="memberIds" type="checkbox" :value="member.value" class="bg-form-ctrl"
          :disabled="isInSection(selectedSection, member.value)" />
        <label :for="member.value">{{ member.label }}</label>
      </div>
      <ErrorMessage name="memberIds" />
    </div>
    <div class="mb-2 flex flex-col w-full">
      <label for="emails">New Member Emails (one per line)</label>
      <Field name="emails" as="textarea" rows="4" class="bg-form-ctrl" />
      <ErrorMessage name="emails" />
    </div>

    <div class="mt-2 flex justify-between w-full">
      <button type="submit" class="btn-plain" :data-reset="true">
        Add and add more
      </button>
      <button type="submit" class="btn-primary">
        Add and close
      </button>
    </div>
  </Form>
</template>
