import type { UserCommon } from './user.common'

type TeamMemberRole = 'admin' | 'manager' | 'team_member'

export const roleToString = (role: TeamMemberRole) => {
  switch (role) {
    case 'admin':
      return 'Admin'
    case 'manager':
      return 'Manager'
    default:
      return 'Team member'
  }
}

export const roles: TeamMemberRole[] = [
  'team_member',
  'manager',
  'admin',
]

export type OrganizationTeamMemberFull = {
  id: string
  organization_id: string
  user_id: string
  role: TeamMemberRole
  created_at: string
  updated_at: string
  team_member?: UserCommon
}
