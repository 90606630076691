<script setup lang="ts">
import { computed, ref } from 'vue'

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue'
import { ChevronUpDownIcon } from '@heroicons/vue/20/solid'

import BasicDialog from '@/components/BasicDialog.vue'

import { useApi } from '@/services/api'

import { useNotificationStore } from '@/stores/notification'

import type { OrganizationFullModel } from '@/types/api/models/organization.full'
import type { SectionFullModel } from '@/types/api/models/section.full'

import { isLeft } from '@/utils/either'

import ConnectionCard from '../ConnectionCard.vue'


const props = defineProps<{
  isOpen: boolean
  organization: OrganizationFullModel
  section: SectionFullModel
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'refresh'): void
}>()

const { section: sectionSvc } = useApi()
const notificationStore = useNotificationStore()

const query = ref('')

const filteredTeamMembers = computed(() =>
  query.value === ''
    ? props.organization.team || []
    : (props.organization.team || []).filter(m => {
        return m.name.toLowerCase().includes(query.value.toLowerCase())
      })
)

const addStaffMember = (id: string) => {
  sectionSvc.addStaffMember(props.section.id, id)
    .then(res => {
      if (isLeft(res)) {
        notificationStore.add({ error: true })
        return;
      }
      emit('refresh')
    })
    .catch(err => {
      notificationStore.add({ error: true, message: err })
    })
}

const removeStaffMember = (id: string) => {
  sectionSvc.removeStaffMember(props.section.id, id)
    .then(res => {
      if (isLeft(res)) {
        notificationStore.add({ error: true })
        return;
      }
      emit('refresh')
    })
    .catch(err => {
      notificationStore.add({ error: true, message: err })
    })
}
</script>

<template>
  <BasicDialog :is-open="isOpen" @close="$emit('close')">
    <template #title>
      {{ section.name }}
    </template>

    <template #main>
      <div class="flex justify-end">
        <button
          @click="sectionSvc.deleteSection(section.id).then(res => {
            if (isLeft(res)) {
              notificationStore.add({ error: true })
              return;
            }
            emit('close')
            emit('refresh')
          })"
          class="text-red-500"
        >
          Delete section
        </button>
      </div>
      <h2>Members</h2>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div v-for="m in section.staff" :key="m.id">
          <ConnectionCard
            :removeable="section.ownerId !== m.id"
            @remove="removeStaffMember(m.id)"
            :name="m.name"
            class="rounded-lg border-white border-2"
          />
        </div>
      </div>

      <Combobox
        nullable
        :default-value="null"
        @update:model-value="v => !v || addStaffMember(v.id)"
      >
        <div class="relative mt-4">
          <h3>Add members</h3>

          <div
            class="relative w-full overflow-hidden rounded bg-form-ctrl"
          >
            <ComboboxInput
              @change="query = $event.target.value"
              :display-value="(m: any) => m?.name"
              class="w-full bg-form-ctrl rounded pl-2"
              ref="queryInput"
            />
            <ComboboxButton
              class="absolute inset-y-0 right-0 flex items-center pr-2"
            >
              <ChevronUpDownIcon
                class="h-5 w-5"
                aria-hidden="true"
              />
            </ComboboxButton>
          </div>

          <TransitionRoot
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            @after-leave="query = ''"
          >
            <ComboboxOptions class="bg-form-ctrl rounded">
              <ComboboxOption
                v-for="m in filteredTeamMembers"
                :key="m.id"
                :value="m"
                :disabled="section.staff?.some(s => s.id === m.id)"
                v-slot="{ active }"
              >
                <li
                  class="relative cursor-default select-none"
                  :class="{
                    'bg-amber-400 text-black': active,
                    'bg-light': section.staff?.some(s => s.id === m.id),
                  }"
                >
                  {{ m.name }}
                </li>
              </ComboboxOption>
            </ComboboxOptions>
          </TransitionRoot>
        </div>
      </Combobox>
    </template>
  </BasicDialog>
</template>
