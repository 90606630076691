import { useOrganization } from './organization'
import { usePrepList } from './prep-list'
import { useSection } from './section'
import { useUser } from './user'


export const useApi = () => {
  const baseUrl = `${import.meta.env.VITE_BACKEND_URL || ''}/api`

  return {
    organization: useOrganization(`${baseUrl}/organization`),
    prepList: usePrepList(`${baseUrl}/prep-list`),
    section: useSection(`${baseUrl}/section`),
    user: useUser(`${baseUrl}/user`),
  }
}
