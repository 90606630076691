import { ref, watch } from 'vue'

import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notification'

import type {Model} from "@/types/serialization";
import type { PrepItemBase } from '@/types/api/raw/prep-item.base'
import type { PrepItemFull } from '@/types/api/raw/prep-item.full'
import type { PrepItemSettable } from '@/types/api/raw/prep-item.settable'
import type { PrepListBase } from '@/types/api/raw/prep-list.base'
import type { PrepListFull } from '@/types/api/raw/prep-list.full'
import type { PrepListSettable } from '@/types/api/raw/prep-list.settable'
import type { SectionFull } from '@/types/api/raw/section.full'
import type {SectionListBase, SectionListSettable} from "@/types/api/raw/section-list";
import type { UserCommon } from '@/types/api/raw/user.common'

import type { PrepItemFullModel } from '@/types/api/models/prep-item.full'
import type { PrepItemSettableModel } from '@/types/api/models/prep-item.settable'
import type { PrepListFullModel } from '@/types/api/models/prep-list.full'
import type { PrepListSettableModel } from '@/types/api/models/prep-list.settable'
import type { SectionFullModel } from '@/types/api/models/section.full'
import type { UserCommonModel } from '@/types/api/models/user.common'

import { useWebSocket, type Socket } from '../util/websocket'
import { useSerialization } from '../util/serialization'
import type { PrepListBaseModel } from '@/types/api/models/prep-list.base'
import type { PrepItemBaseModel } from '@/types/api/models/prep-item.base'


type ConnectingEvent = {
  event: 'CONNECTING'
  payload: never
}

type ReadyEvent = {
  event: 'READY'
  payload: { lists: PrepListFull[] }
}

type ForbiddenEvent = {
  event: 'FORBIDDEN'
  payload: never
}

type ErrorEvent = {
  event: 'ERROR'
  payload: never
}

type PrepListCreatedEvent = {
  event: 'PREP_LIST_CREATED'
  payload: { list: PrepListBase }
}

type PrepListUpdatedEvent = {
  event: 'PREP_LIST_UPDATED'
  payload: { list: PrepListFull }
}

type PrepListDeletedEvent = {
  event: 'PREP_LIST_DELETED'
  payload: { list: PrepListFull }
}

type PrepItemCreatedEvent = {
  event: 'PREP_ITEM_CREATED'
  payload: { list: PrepListFull }
}

type PrepItemUpdateEvent = {
  event: 'PREP_ITEM_UPDATED'
  payload: { list: PrepListFull }
}

type PrepItemDeletedEvent = {
  event: 'PREP_ITEM_DELETED'
  payload: { list: PrepListFull }
}


type PrepListWsMessage =
  | PrepListCreatedEvent
  | PrepListUpdatedEvent
  | PrepListDeletedEvent
  | PrepItemCreatedEvent
  | PrepItemUpdateEvent
  | PrepItemDeletedEvent
  | ConnectingEvent
  | ReadyEvent
  | ForbiddenEvent
  | ErrorEvent


type WSRequest<T, U> = {
  req: T,
  payload: U;
}

type SectionListCreateRequest = WSRequest<'CREATE_SECTION_LIST', SectionListBase>

type SectionListUpdateRequest = WSRequest<'UPDATE_SECTION_LIST', SectionListSettable>

type PrepListCreateRequest = WSRequest<'CREATE_PREP_LIST', {
  section_id: string;
  list: PrepListBase;
}>

type PrepListUpdateRequest = WSRequest<'UPDATE_PREP_LIST', {
  id: string;
  update: PrepListSettable;
}>

type PrepListDeleteRequest = WSRequest<'DELETE_PREP_LIST', {
  id: string;
}>

type PrepItemCreateRequest = WSRequest<'CREATE_PREP_ITEM', {
  list_id: string;
  item: PrepItemBase;
}>

type PrepItemUpdateRequest = WSRequest<'UPDATE_PREP_ITEM', {
  id: string;
  list_id: string;
  update: PrepItemSettable;
}>

type PrepItemDeleteRequest = WSRequest<'DELETE_PREP_ITEM', {
  id: string;
  list_id: string;
}>


type PrepListWsRequest =
  | SectionListCreateRequest
  | SectionListUpdateRequest
  | PrepListCreateRequest
  | PrepListUpdateRequest
  | PrepListDeleteRequest
  | PrepItemUpdateRequest
  | PrepItemCreateRequest
  | PrepItemDeleteRequest


const itemSrlz = useSerialization<PrepItemFull, PrepItemFullModel>({
  rawToModel: {
    values: { finished_at: (v?: string) => v ? new Date(v) : null },
  },
})
const itemDeserializer = itemSrlz.makeDeserializer()
const itemSerializer = itemSrlz.makeSerializer()

const sectionSrlz = useSerialization<SectionFull, SectionFullModel>()
const userSrlz = useSerialization<UserCommon, UserCommonModel>()

const cfg = {
  rawToModel: {
    values: {
      due_at: (v: string) => new Date(v),
      section: sectionSrlz.makeDeserializer().deserializeRecord,
      created_by: userSrlz.makeDeserializer().deserializeRecord,
      prep_items: itemDeserializer.deserializeArray,
    },
  },
  modelToRaw: {
    values: { dueAt: (v: Date) => v.toISOString() },
  },
}
const prepSrlz = useSerialization<PrepListFull, PrepListFullModel>(cfg)
const listDeserializer = prepSrlz.makeDeserializer()
const listSerializer = prepSrlz.makeSerializer()
const sectionListSerializer = useSerialization<SectionListBase, Model<SectionListBase>>().makeSerializer()


const socket = ref<Socket | null>(null)
const ready = ref<boolean>(false)
const lists = ref<PrepListFullModel[]>([])
const subscribers = ref<number>(0)


export const usePrepList = (route: string) => {
  const notificationStore = useNotificationStore()

  const connect = () => {
    subscribers.value++
    if (socket.value && !socket.value.isClosed) {
      return;
    }

    const { getToken } = useAuthStore()
    const { socket: _socket } = useWebSocket(route, getToken)
    console.log('new socket received')

    console.log('setting "prep-list" watcher')
    watch(
      () => <MessageEvent<string> | null>socket.value?.message,
      (ev) => {
        console.log('new prep-list event received:', ev)
        if (!ev) {
          return;
        }
        const message: PrepListWsMessage = JSON.parse(ev.data)
        switch (message.event) {
          case 'CONNECTING': {
            return;
          }
          case 'READY': {
            ready.value = true
            const ls = listDeserializer.deserializeArray(message.payload.lists)
            console.log('prep-lists received:', ls)
            lists.value = ls
            return;
          }
          case 'FORBIDDEN': {
            notificationStore.add({
              error: true,
              message: 'You are not allowed to perform this action',
            })
            console.error('error received:', message)
            return;
          }
          case 'ERROR': {
            notificationStore.add({ error: true })
            console.error('error received:', message)
            return;
          }
          case 'PREP_LIST_CREATED': {
            const list = listDeserializer.deserializeRecord(message.payload.list)
            console.log('new prep-list received:', list)
            const idx = lists.value.findIndex(l => l.id === list.id)
            if (idx < 0) {
              lists.value.push(list)
            }
            return;
          }
          case 'PREP_LIST_UPDATED':
          case 'PREP_ITEM_CREATED':
          case 'PREP_ITEM_UPDATED':
          case 'PREP_ITEM_DELETED':
          {
            const list = listDeserializer.deserializeRecord(message.payload.list)
            console.log('prep-list update received:', list)
            const idx = lists.value.findIndex(l => l.id === list.id)
            if (idx >= 0) {
              lists.value.splice(idx, 1, list)
            }
            return;
          }
          case 'PREP_LIST_DELETED': {
            const listId = message.payload.list.id
            const idx = lists.value.findIndex(l => l.id === listId)
            lists.value.splice(idx, 1)
            return;
          }
          default:
            console.log('unrecognized event:', message)
        }
      }
    )

    watch(
      () => _socket.value.isOpen,
      isOpen => ready.value = isOpen
    )

    console.log('setting "error" watcher')
    watch(
      () => <Event | null>_socket.value.error,
      (ev: Event | null) => {
        if (!ev) {
          return;
        }
        notificationStore.add({ error: true })
        console.log('onError called with event:', ev)
      }
    )

    console.log('connecting socket')
    _socket.value.connect()

    socket.value = _socket.value
    console.log('socket ref updated')
  }

  const _sendRequest = (req: PrepListWsRequest) => {
    if (!socket.value) {
      console.error('Cannot dispatch request. WS not connected.')
      return false
    }
    if (!ready.value) {
      console.error('Cannot dispatch request. WS connection not ready.')
      return false
    }
    socket.value.send(req.req, req.payload)
    return true
  }

  const createSectionList = (
      list: Model<SectionListBase>,
  ) => _sendRequest({
    req: 'CREATE_SECTION_LIST',
    payload: sectionListSerializer.serializeRecord(list),
  })

  const updateSectionList = (
      data: Model<SectionListSettable>,
  ) => _sendRequest({
    req: 'UPDATE_SECTION_LIST',
    payload: sectionListSerializer.serializeRecord(data),
  })

  const createList = (
    sectionId: string,
    list: PrepListBaseModel,
  ) => _sendRequest({
    req: 'CREATE_PREP_LIST',
    payload: {
      section_id: sectionId,
      list: listSerializer.serializeRecord(list),
    },
  })

  const updateList = (
    listId: string,
    data: PrepListSettableModel,
  ) => _sendRequest({
    req: 'UPDATE_PREP_LIST',
    payload: {
      id: listId,
      update: listSerializer.serializeRecord(data),
    },
  })

  const deleteList = (listId: string) => _sendRequest({
    req: 'DELETE_PREP_LIST',
    payload: {
      id: listId,
    },
  })

  const createItem = (listId: string, item: PrepItemBaseModel) => _sendRequest({
    req: 'CREATE_PREP_ITEM',
    payload: {
      list_id: listId,
      item: itemSerializer.serializeRecord(item),
    },
  })

  const updateItem = (
    listId: string,
    itemId: string,
    data: PrepItemSettableModel,
  ) => _sendRequest({
    req: 'UPDATE_PREP_ITEM',
    payload: {
      id: itemId,
      list_id: listId,
      update: itemSerializer.serializeRecord(data),
    },
  })

  const deleteItem = (listId: string, itemId: string) => _sendRequest({
    req: 'DELETE_PREP_ITEM',
    payload: { id: itemId, list_id: listId },
  })

  const disconnect = () => {
    if (--subscribers.value) {
      return;
    }
    socket.value?.disconnect()
    socket.value = null
  }

  return {
    connect,
    disconnect,
    lists,
    createSectionList,
    updateSectionList,
    createList,
    updateList,
    deleteList,
    createItem,
    updateItem,
    deleteItem,
  }
}
