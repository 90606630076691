<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import * as yup from 'yup'

import { useAuth } from '@/services/auth'
import { isRight, unwrapEither } from '@/utils/either';
import type { LoginError } from '@/types/auth.errors';

const schema = yup.object({
  email: yup.string().trim().required().email(),
  password: yup.string().required().min(8),
})

const router = useRouter()
const errRes = ref<string | null>(null)
const { login } = useAuth()

const handleLoginError = (error: LoginError) => {
  switch (error) {
    case 'INVALID_CREDENTIALS':
      errRes.value = 'Your email or password is incorrect.'
      break;
    case 'UNKNOWN_ERROR':
      errRes.value = 'Something went wrong.'
      break;
    default:
      console.error(`Unrecognized error passed to login error handler: ${error}`)
  }
}

const onSubmit = (values: { email: string; password: string }) => {
  errRes.value = null

  login(values)
    .then(either => {
      if (isRight(either)) {
        router.push({ name: 'prep-list-home' })
      } else {
        handleLoginError(unwrapEither(either))
      }
    })
}
</script>

<template>
  <div class="flex flex-col justify-center items-center bg-dark">
    <h1>Login</h1>
    <Form class="flex flex-col items-start" @submit="onSubmit" :validation-schema="schema">
      <label for="email">Email</label>
      <Field name="email" type="email" class="bg-form-ctrl" />
      <ErrorMessage name="email" />
      <label for="password">Password</label>
      <Field name="password" type="password" class="bg-form-ctrl" />
      <ErrorMessage name="password" />
      <button type="submit" class="btn-primary my-4">Submit</button>
    </Form>
    <div v-if="errRes">{{ errRes }}</div>
    <router-link :to="{ name: 'signup' }">Go to signup</router-link>
  </div>
</template>
