import { useAuthStore } from '@/stores/auth'
import { useProgressStore } from '@/stores/progress'

import type { Either } from '@/types/either'
import type { PrepListBase } from '@/types/api/raw/prep-list.base'
import type { PrepListFull } from '@/types/api/raw/prep-list.full'
import type { PrepListSettable } from '@/types/api/raw/prep-list.settable'
import type { SectionFull } from '@/types/api/raw/section.full'
import type { UserCommon } from '@/types/api/raw/user.common'

import type { PrepListBaseModel } from '@/types/api/models/prep-list.base'
import type { PrepListFullModel } from '@/types/api/models/prep-list.full'
import type { PrepListSettableModel } from '@/types/api/models/prep-list.settable'
import type { SectionFullModel } from '@/types/api/models/section.full'
import type { UserCommonModel } from '@/types/api/models/user.common'

import { makeLeft, makeRight } from '@/utils/either'

import { useHttp } from '../util/http'
import { useSerialization } from '../util/serialization'



type GetPrepListsError = 'UNKNOWN_ERROR'
type CreatePrepListError = 'UNKNOWN_ERROR'
type DeletePrepListError = 'UNKNOWN_ERROR'
type UpdatePrepListError = 'UNKNOWN_ERROR'


const translateGetListError = (message: string): GetPrepListsError => {
  switch (message) {
    default:
      return 'UNKNOWN_ERROR'
  }
}

const translateCreateError = (message: string): CreatePrepListError => {
  switch (message) {
    default:
      return 'UNKNOWN_ERROR'
  }
}

const translateDeleteError = (message: string): DeletePrepListError => {
  switch (message) {
    default:
      return 'UNKNOWN_ERROR'
  }
}

const translateUpdateError = (message: string): UpdatePrepListError => {
  switch (message) {
    default:
      return 'UNKNOWN_ERROR'
  }
}


const { get, post, delete: doDelete, patch } = useHttp()

const sectionSrlz = useSerialization<SectionFull, SectionFullModel>()
const userSrlz = useSerialization<UserCommon, UserCommonModel>()

const cfg = {
  rawToModel: {
    values: {
      due_at: (v: string) => new Date(v),
      section: sectionSrlz.makeDeserializer().deserializeRecord,
      created_by: userSrlz.makeDeserializer().deserializeRecord,
    },
  },
  modelToRaw: {
    values: { dueAt: (v: Date) => v.toISOString() },
  },
}
const prepSrlz = useSerialization<PrepListFull, PrepListFullModel>(cfg)

const { makeSerializer, makeDeserializer } = prepSrlz
const { serializeParams, serializeRecord } = makeSerializer()
const { deserializeArray } = makeDeserializer()


export const usePrepList = (route: string) => {
  const { getToken } = useAuthStore()
  const { track } = useProgressStore()

  const getList = async (params?: {
    include?: ('createdBy' | 'section' | 'prepItems')[]
  }): Promise<Either<GetPrepListsError, PrepListFullModel[]>> => {
    try {
      const p = serializeParams(params)
      const res = await track(get<PrepListFull[]>(route, p, getToken()))
      if ('statusCode' in res) {
        return makeLeft(translateGetListError(res.message))
      }
      return makeRight(deserializeArray(res))
    } catch (err) {
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  const create = async (
    data: PrepListBaseModel,
  ): Promise<Either<CreatePrepListError, 'ok'>> => {
    try {
      const p = serializeRecord(data)
      const t = getToken()
      const res = await track(post<PrepListBase, PrepListFull>(route, p, t))
      if ('statusCode' in res) {
        return makeLeft(translateCreateError(res.message))
      }
      return makeRight('ok')
    } catch (err) {
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  const deletePrepList = async (
    id: string,
  ): Promise<Either<DeletePrepListError, 'ok'>> => {
    try {
      const res = await track(doDelete<{}>(`${route}/${id}`, getToken()))
      console.log('res:', res)
      if ('statusCode' in res) {
        return makeLeft(translateDeleteError(res.message))
      }
      return makeRight('ok')
    } catch (err) {
      console.log('err:', err)
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  const update = async (
    id: string,
    data: PrepListSettableModel,
  ): Promise<Either<UpdatePrepListError, 'ok'>> => {
    try {
      const p = serializeRecord(data)
      const t = getToken()
      const res = await track(patch<PrepListSettable, {}>(`${route}/${id}`, p, t))
      if ('statusCode' in res) {
        return makeLeft(translateUpdateError(res.message))
      }
      return makeRight('ok')
    } catch (err) {
      return makeLeft('UNKNOWN_ERROR')
    }
  }

  return { getList, create, delete: deletePrepList, update }
}
