<script setup lang="ts">
import {nextTick} from 'vue'
import * as yup from 'yup'
import {ErrorMessage, useField, useFieldArray, useForm} from 'vee-validate'
import {toTypedSchema} from '@vee-validate/yup'

import type {OrganizationFullModel} from '@/types/api/models/organization.full';

import type {PrepListBaseModel} from '@/types/api/models/prep-list.base';
import type {PrepListFullModel} from "@/types/api/models/prep-list.full";
import type {SectionFullModel} from "@/types/api/models/section.full";
import type {SectionListFull} from "@/types/api/raw/section-list";
import type {Model} from "@/types/serialization";


const props = defineProps<{
  organizations: OrganizationFullModel[]
  userId?: string
  prepLists: PrepListFullModel[],
}>()

if (!props.userId) {
  console.error('No userId. Section selection may not work properly.')
}

const emit = defineEmits<{
  (e: 'new-prep-list', data: PrepListBaseModel): void
}>()

const prepItemSchema = yup.object({
  text: yup.string()
      .trim()
      .test({
        name: 'empty',
        message: 'item cannot be empty',
        test: function (value) {
          return !this.parent.enabled || !!value
        }
      })
      .test({
        name: 'min',
        message: 'item too short',
        test: function (value) {
          return !this.parent.enabled || (!!value && value!.length >= 2)
        }
      }),
  enabled: yup.boolean().defined().required().default(true),
})

const prepListSchema = yup.object({
  list: yup.object({
    orgId: yup.string().uuid().required(),
    sectionId: yup.string().uuid().required(),
    listId: yup.string().uuid().nullable(),
  }).required().default(null),
  name: yup.string().trim().required().min(2),
  dueAt: yup.string().matches(/\d{4}-\d{2}-\d{2}/).required(),
  handover: yup.string(),
  prepItems: yup.array(prepItemSchema).required().min(1),
  published: yup.boolean().defined().required(),
})

const { handleSubmit, setFieldValue, values } = useForm({
  validationSchema: toTypedSchema(prepListSchema),
})
const { push, fields } = useFieldArray<{text: string, enabled: boolean}>('prepItems')
const listField = useField<{ orgId: string, sectionId: string, listId: string | null }>('list')

const onSectionListChange = () => {
  const {orgId, sectionId, listId} = listField.value.value
  const org = props.organizations.find(o => o.id === orgId)
  const section = org?.sections.find(s => s.id === sectionId)
  const list = section?.lists?.find(l => l.id === listId)
  if (!values.name) {
    setFieldValue('name', list?.name ?? section?.name)
  }
  if (list?.items.length) {
    for (let i = 0; i < list.items.length; i++) {
      if (!fields.value.find(f => f.value.text === list.items[i])) {
        push({text: list.items[i], enabled: false})
      }
    }
  }
}

const _getItemIdx = (name: string | null) => {
  if (!name) {
    return -1
  }
  const found = name.match(/\[(\d+)]/)
  if (!found) {
    return -1
  }
  return +found[1]
}

const addItem = () => {
  push({ text: '', enabled: true })
  nextTick(() => {
    const selector = 'input[id^="text_"]'
    const nodes = document.querySelectorAll<HTMLInputElement>(selector)
    console.log(`${Date.now()} PrepListForm.vue:88\tnodes:\t`, nodes);
    if (!nodes.length) {
      return;
    }
    let lastEl: HTMLInputElement | null = null
    let lastIdx = -1
    for (const nextEl of nodes) {
      const nextIdx = _getItemIdx(nextEl.getAttribute('name'))
      if (nextIdx > lastIdx) {
        lastEl = nextEl
        lastIdx = nextIdx
      }
    }
    lastEl?.focus()
  })
}

const onSubmit = handleSubmit((value) => {
  const date = value.dueAt.split('-').map(s => +s)
  const dueAt = new Date(date[0], date[1] - 1, date[2], 23, 59, 59)
  const published = value.published
  const sectionId = value.list.sectionId
  const prepItems = value.prepItems.filter(i => i.enabled).map(({ text }) => ({ text: text! }))
  emit('new-prep-list', { ...value, prepItems, sectionId, published, dueAt })
})

function getSortedOrgs(orgs: OrganizationFullModel[]): OrganizationFullModel[] {
  return orgs.sort((a, b) => {
    const aNumLists = a.sections.reduce((acc, s) => acc + getNumLists(s), 0)
    const bNumLists = b.sections.reduce((acc, s) => acc + getNumLists(s), 0)
    return bNumLists - aNumLists
  })
  function getNumLists(section: SectionFullModel): number {
    return props.prepLists.filter(pl => pl.sectionId === section.id).length
  }
}

function getSortedSections(org: OrganizationFullModel): SectionFullModel[] {
  const role = org.team.find(t => t.id === props.userId)?.role
  const filteredSections = org.sections.filter(s => {
        if (role === 'admin' || role === 'manager') {
          return true
        }
        return !!s.staff?.find(m => m.id === props.userId)
      })
  return filteredSections.sort((a, b) => {
    return a.name.localeCompare(b.name)
  })
}

function getSortedSectionLists(lists: Model<SectionListFull>[]): Model<SectionListFull>[] {
  return lists?.sort((a, b) => {
    return a.name.localeCompare(b.name)
  })
}
</script>

<template>
  <div>
    <form
      class="flex flex-col items-start"
      @submit="onSubmit"
      :validation-schema="prepListSchema"
      novalidate
    >
      <div class="flex flex-col items-start md:grid md:grid-cols-2 md:gap-4 w-full">

        <!-- section list field -->
        <div class="flex flex-col items-start w-full md:col-span-2">
          <label for="list">Section List</label>
          <Field
            name="list"
            as="select"
            class="bg-form-ctrl rounded w-full"
            @change="onSectionListChange"
          >
            <template v-for="org of getSortedOrgs(props.organizations)" :key="org.id">
              <optgroup :label="org.name" v-if="getSortedSections(org).length">
                <template v-for="section of getSortedSections(org)" :key="section.id">
                  <option v-for="list of getSortedSectionLists(section.lists)" :key="list.id" :value="{orgId: org.id, sectionId: section.id, listId: list.id}">
                    {{ section.name}}{{ list.name !== section.name || section.lists.length ? ' - ' + list.name : '' }}
                  </option>
                  <option :value="{orgId: org.id, sectionId: section.id, listId: null}">
                    {{ section.name }} - Start from blank list
                  </option>
                </template>
              </optgroup>
            </template>
          </Field>
          <ErrorMessage name="list" />
        </div>

        <!-- due date field -->
        <div class="flex flex-col items-start w-full">
          <label for="dueAt">Date</label>
          <Field name="dueAt" type="date" class="bg-form-ctrl rounded w-full" />
          <ErrorMessage name="dueAt" />
        </div>

        <!-- name field -->
        <div class="flex flex-col items-start w-full">
          <label for="name">Name</label>
          <Field name="name" type="text" class="bg-form-ctrl rounded w-full" />
          <ErrorMessage name="name" />
        </div>
      </div>

      <!-- prep-items inputs and controls -->
      <div class="flex flex-col items-start divide-y divide-zinc-600 my-2 w-full">
        <label>Prep Items</label>
        <div
          v-for="(field, idx) in fields"
          :key="field.key"
          class="flex flex-col items-start w-full"
        >
          <div class="flex w-full">
            <input
              type="checkbox"
              :id="`enabled_${idx}`"
              :name="`prepItems[${idx}].enabled`"
              class="bg-form-ctrl rounded mr-2"
              :checked="field.value.enabled"
              @change="field.value.enabled = !field.value.enabled"
            />
            <Field
              :id="`text_${idx}`"
              :name="`prepItems[${idx}].text`"
              @keydown.enter.prevent="addItem"
              class="bg-form-ctrl rounded mr-2 flex-grow item-field"
              :disabled="!field.value.enabled"
            />
          </div>
          <ErrorMessage :name="`prepItems[${idx}].text`" />
        </div>
<!--TODO: always one blank item at the end-->
        <div class="flex justify-end w-full">
          <button type="button" @click="addItem" class="btn-accent">
            Add Prep Item
          </button>
        </div>
        <ErrorMessage name="prepItems" />
      </div>

      <!-- handover field -->
      <div class="flex flex-col items-start w-full">
        <label for="handover">Handover</label>
        <Field
          name="handover"
          as="textarea"
          rows="10"
          class="bg-form-ctrl rounded w-full"
        />
        <ErrorMessage name="textarea" />
      </div>

      <Field name="published" type="checkbox" :value="true" class="hidden" />

      <div class="flex justify-between w-full">
        <button class="btn-plain mr-2" @click="setFieldValue('published', false)">
          Save Draft
        </button>
        <button class="btn-primary" @click="setFieldValue('published', true)">
          Publish
        </button>
      </div>
    </form>
  </div>
</template>
