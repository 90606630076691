<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import * as yup from 'yup'

import { useAuth } from '@/services/auth'
import { useApi } from '@/services/api';

import { isRight, unwrapEither } from '@/utils/either';

import type { SignupError } from '@/types/auth.errors';


const router = useRouter()
const { signup } = useAuth()
const { organization: organizationSvc } = useApi()

const schema = yup.object({
  name: yup.string().trim().required(),
  email: yup.string().trim().required().email(),
  password: yup.string().required().min(8),
})

const errRes = ref<string | null>(null)

const handleSignupError = (error: SignupError) => {
  switch (error) {
    case 'EMAIL_EXISTS':
      errRes.value = 'Email already exists.'
      break;
    case 'UNKNOWN_ERROR':
      errRes.value = 'Something went wrong.'
      break;
    default:
      console.error(`Unrecognized error passed to login error handler: ${error}`)
  }
}

const onSubmit = (values: {
  name: string;
  email: string;
  password: string;
}) => {
  errRes.value = null

  signup(values)
    .then(either => {
      if (isRight(either)) {
        return onSignupSuccess(values.name.split(' ')[0])
      }
      handleSignupError(unwrapEither(either))
    })
}

const onSignupSuccess = (username: string) => {
  organizationSvc.create({ name: `${username}'s Org` })
    .then(either => {
      if (isRight(either)) {
        return router.push({ name: 'prep-list-home' })
      }
      console.error('error creating org:', unwrapEither(either))
    })
}
</script>

<template>
  <div class="flex flex-col justify-center items-center bg-dark">
    <h1>Signup</h1>
    <Form class="flex flex-col items-start" @submit="onSubmit" :validation-schema="schema">
      <label for="name">Name</label>
      <Field name="name" type="text" class="bg-form-ctrl" />
      <ErrorMessage name="name" />
      <label for="email">Email</label>
      <Field name="email" type="email" class="bg-form-ctrl" />
      <ErrorMessage name="email" />
      <label for="password">Password</label>
      <Field name="password" type="password" class="bg-form-ctrl" />
      <ErrorMessage name="password" />
      <button type="submit" class="btn-primary my-4">Submit</button>
    </Form>
    <div v-if="errRes">{{ errRes }}</div>
    <router-link :to="{ name: 'login' }">Go to login</router-link>
  </div>
</template>

<style>
</style>
