import { ref, watch } from 'vue'

import type { UserCommonWithEmailModel } from '@/types/api/models/user.common'

declare global {
  interface Window {
    clarity?: (action: 'set' | 'identify', ...args: any[]) => void;
  }
}

const TOKEN_KEY = 'hospa-auth-token'
const USER_KEY = 'hospa-user'


const token = ref(localStorage.getItem(TOKEN_KEY))
watch(token, t => {
  if (t) {
    return localStorage.setItem(TOKEN_KEY, t)
  }
  localStorage.removeItem(TOKEN_KEY)
})

const user = ref<UserCommonWithEmailModel | null>(null)
const _uStr = localStorage.getItem(USER_KEY)
if (_uStr) {
  user.value = JSON.parse(_uStr)
}
watch(user, u => {
  if (u) {
    if (u.email) {
      window.clarity?.('set', 'userId', u.email)
      window.clarity?.('identify', u.email, null, null, u.email)
    }
    return localStorage.setItem(USER_KEY, JSON.stringify(u))
  }
  localStorage.removeItem(USER_KEY)
})

export const usePersistentStorage = () => ({ token, user })
