<script setup lang="ts">
import {nextTick} from 'vue'
import * as yup from 'yup'
import {useField, useFieldArray, useForm} from 'vee-validate'
import {toTypedSchema} from '@vee-validate/yup'

import type {OrganizationFullModel} from '@/types/api/models/organization.full';

import type {SectionFullModel} from "@/types/api/models/section.full";
import type {SectionListBase} from '@/types/api/raw/section-list';
import type {SectionListFull} from "@/types/api/raw/section-list";
import type {Model} from "@/types/serialization";


const props = defineProps<{
  organizations: OrganizationFullModel[]
  userId?: string
}>()

if (!props.userId) {
  console.error('No userId. Section selection may not work properly.')
}

const emit = defineEmits<{
  (e: 'new-section-list', data: Model<SectionListBase>): void
  (e: 'update-section-list', data: Model<SectionListBase> & { listId: string }): void
}>()

const itemSchema = yup.string()
    .trim()
    .required('item cannot be empty')
    .min(2, 'item too short')

const sectionListSchema = yup.object({
  list: yup.object({
    orgId: yup.string().uuid().required(),
    sectionId: yup.string().uuid().required(),
    listId: yup.string().uuid().nullable(),
  }).required().default(null),
  name: yup.string().trim().required().min(2),
  items: yup.array(itemSchema).required(),
})

const {handleSubmit, setFieldValue, values} = useForm({
  validationSchema: toTypedSchema(sectionListSchema),
})
const {push, remove, fields} = useFieldArray('items')
const listField = useField<{ orgId: string, sectionId: string, listId: string | null }>('list')

const onSectionListChange = () => {
  const {orgId, sectionId, listId} = listField.value.value
  const org = props.organizations.find(o => o.id === orgId)
  const section = org!.sections.find(s => s.id === sectionId)
  const list = section!.lists?.find(l => l.id === listId)
  setFieldValue('name', list?.name ?? section!.name)
  setFieldValue('items', list?.items ?? [])
}

const _getItemIdx = (name: string | null) => {
  if (!name) {
    return -1
  }
  const found = name.match(/\[(\d+)]/)
  if (!found) {
    return -1
  }
  return +found[1]
}

const addItem = () => {
  push('')
  nextTick(() => {
    const selector = 'input[name*="items"]'
    const nodes = document.querySelectorAll<HTMLInputElement>(selector)
    if (!nodes.length) {
      return;
    }
    let lastEl: HTMLInputElement | null = null
    let lastIdx = -1
    for (const nextEl of nodes) {
      const nextIdx = _getItemIdx(nextEl.getAttribute('name'))
      if (nextIdx > lastIdx) {
        lastEl = nextEl
        lastIdx = nextIdx
      }
    }
    lastEl?.focus()
  })
}

const onSubmit = handleSubmit((value) => {
  const sectionId = value.list.sectionId
  const listId = value.list.listId
  if (!listId) {
    emit('new-section-list', { ...value, sectionId })
  } else {
    emit('update-section-list', { ...value, sectionId, listId })
  }
})

function getSortedOrgs(orgs: OrganizationFullModel[]): OrganizationFullModel[] {
  return orgs.sort((a, b) => {
    return a.name.localeCompare(b.name)
  })
}

function getSortedSections(org: OrganizationFullModel): SectionFullModel[] {
  const role = org.team.find(t => t.id === props.userId)?.role
  const filteredSections = org.sections.filter(s => {
    if (role === 'admin' || role === 'manager') {
      return true
    }
    return s.ownerId === props.userId
  })
  return filteredSections.sort((a, b) => {
    return a.name.localeCompare(b.name)
  })
}

function getSortedSectionLists(lists: Model<SectionListFull>[]): Model<SectionListFull>[] {
  return lists?.sort((a, b) => {
    return a.name.localeCompare(b.name)
  })
}
</script>

<template>
  <div>
    <form
        class="flex flex-col items-start"
        @submit="onSubmit"
        :validation-schema="sectionListSchema"
        novalidate
    >
      <div class="flex flex-col items-start md:grid md:grid-cols-2 md:gap-4 w-full">

        <!-- section list field -->
        <div class="flex flex-col items-start w-full md:col-span-2">
          <label for="list">Section List</label>
          <Field
              name="list"
              as="select"
              class="bg-form-ctrl rounded w-full"
              @change="onSectionListChange"
          >
            <template v-for="org of getSortedOrgs(props.organizations)" :key="org.id">
              <optgroup :label="org.name" v-if="getSortedSections(org).length">
                <template v-for="section of getSortedSections(org)" :key="section.id">
                  <option v-for="list of getSortedSectionLists(section.lists)" :key="list.id"
                          :value="{orgId: org.id, sectionId: section.id, listId: list.id}">
                    {{ section.name }} - {{ list.name }}
                  </option>
                  <option :value="{orgId: org.id, sectionId: section.id, listId: null}">
                    {{ section.name }} - Create new section list
                  </option>
                </template>
              </optgroup>
            </template>
          </Field>
          <ErrorMessage name="list"/>
        </div>

        <!-- name field -->
        <div class="flex flex-col items-start w-full">
          <label for="name">Name</label>
          <Field name="name" type="text" class="bg-form-ctrl rounded w-full"/>
          <ErrorMessage name="name"/>
        </div>
      </div>

      <!-- prep-items inputs and controls -->
      <div class="flex flex-col items-start divide-y divide-zinc-600 my-2 w-full">
        <label>Prep Items</label>
        <div
            v-for="(field, idx) in fields"
            :key="field.key"
            class="flex flex-col items-start w-full"
        >
          <div class="flex w-full">
            <Field
                :id="`text_${idx}`"
                :name="`items[${idx}]`"
                @keydown.enter.prevent="addItem"
                class="bg-form-ctrl rounded mr-2 flex-grow item-field"
            />
            <button type="button" @click="remove(idx)">X</button>
          </div>
          <ErrorMessage :name="`items[${idx}]`"/>
        </div>
        <!--TODO: always one blank item at the end-->
        <div class="flex justify-end w-full">
          <button type="button" @click="addItem" class="btn-accent">
            Add Prep Item
          </button>
        </div>
      </div>

      <div class="flex justify-end w-full">
        <button class="btn-primary">
          {{ listField.value.value?.listId ? 'Update' : 'Create' }} Section List
        </button>
      </div>
    </form>
  </div>
</template>
