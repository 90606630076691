const doFetch = async (req: Request, log = false) => {
  return await fetch(req)
    .then(res => {
      if (res.status === 204) {
        return <any>{};
      }
      return res.json()
    })
    .then(json => {
      if (log) {
        console.log(`response from server: ${JSON.stringify(json)}`)
      }
      return json
    })
}

type GetParams = {
  include?: string[]
  filter?: { [key: string]: any }
}

type ErrorResponse = {
  statusCode: number
  message: string
}

export const useHttp = () => {
  const devLog = import.meta.env.VITE_LOG_LEVEL === 'dev'

  const post = async <T, U>(
    url: string,
    data: T,
    token?: string,
  ): Promise<U | ErrorResponse> => {
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    }
    if (token) {
      headers['Authorization'] = `bearer ${token}`
    }

    const body = JSON.stringify(data)

    if (devLog) {
      console.log(`sending POST request to ${url} with body ${body}`)
    }

    const req = new Request(url, {
      method: 'POST',
      body,
      headers,
    })
    return doFetch(req, devLog)
  }

  const get = async <T>(
    url: string,
    params?: GetParams | null,
    token?: string,
  ): Promise<T | ErrorResponse> => {
    const opts = token ?
      { headers: { 'Authorization': `bearer ${token}` } }
      : undefined

    if (params) {
      const ps: [string, any][] = [];

      if (params.include) {
        for (const i of params.include) {
          ps.push(['_include', i])
        }
      }

      if (params.filter) {
        for (const f of Object.keys(params.filter)) {
          ps.push([f, params.filter[f]])
        }
      }
      url = `${url}?${ps.map(([k, v]) => `${k}=${v}`).join('&')}`
    }

    if (devLog) {
      console.log(`sending GET request to ${url}`)
    }

    const req = new Request(url, opts)
    return doFetch(req, devLog)
  }

  const sendDelete = async <T>(
    url: string,
    token?: string,
  ): Promise<T | ErrorResponse> => {
    const opts: RequestInit = { method: 'DELETE' }
    if (token) {
      opts.headers = {
        'Authorization': `bearer ${token}`,
      }
    }

    if (devLog) {
      console.log(`sending DELETE request to ${url}`)
    }

    const req = new Request(url, opts)
    return doFetch(req, devLog)
  }

  const patch = async <T, U>(
    url: string,
    data: T,
    token?: string,
  ): Promise<U | ErrorResponse> => {
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    }
    if (token) {
      headers['Authorization'] = `bearer ${token}`
    }

    const body = JSON.stringify(data)

    if (devLog) {
      console.log(`sending POST request to ${url} with body ${body}`)
    }

    const req = new Request(url, {
      method: 'PATCH',
      body,
      headers,
    })
    return doFetch(req, devLog)
  }

  return { post, get, delete: sendDelete, patch }
}
