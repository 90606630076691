import type { Either, Left, Right, UnwrapEither } from '@/types/either'


export const unwrapEither: UnwrapEither = <T, U>({ left, right }: Either<T, U>) => {
  if (right !== undefined && left !== undefined) {
    throw new Error('received both left and right');
  }
  if (left !== undefined) {
    return left as NonNullable<T>;
  }
  if (right !== undefined) {
    return right as NonNullable<U>;
  }
  throw new Error('received neither left nor right');
}

export const isLeft = <T, U>(e: Either<T, U>): e is Left<T> => e.left !== undefined;

export const isRight = <T, U>(e: Either<T, U>): e is Right<U> => e.right !== undefined;

export const makeLeft = <T>(value: T): Left<T> => ({ left: value });

export const makeRight = <U>(value: U): Right<U> => ({ right: value });
