import { ref, watch } from 'vue'

import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notification'
import { useAccountStore } from '@/stores/account'

import type { SectionMessageFull } from '@/types/api/raw/section-message.full'
import type { SectionMessageBaseModel } from '@/types/api/models/section-message.base'
import type { SectionMessageFullModel } from '@/types/api/models/section-message.full'

import { useWebSocket, type Socket } from '../util/websocket'
import { useSerialization } from '../util/serialization'


type ConnectingEvent = {
  event: 'CONNECTING'
  payload: never
}

type ReadyEvent = {
  event: 'READY'
  payload: never
}

type ForbiddenEvent = {
  event: 'FORBIDDEN'
  payload: never
}

type ErrorEvent = {
  event: 'ERROR'
  payload: never
}

type NewSectionMessagesEvent = {
  event: 'NEW_SECTION_MESSAGES'
  payload: { messages: SectionMessageFull[] }
}

type SectionMessageUpdatedEvent = {
  event: 'SECTION_MESSAGE_UPDATED'
  payload: { message: SectionMessageFull }
}


type SectionMessageWsNotification =
  | ConnectingEvent
  | ReadyEvent
  | ForbiddenEvent
  | ErrorEvent
  | NewSectionMessagesEvent
  | SectionMessageUpdatedEvent


const msgSrlz = useSerialization<SectionMessageFull, SectionMessageFullModel>({
  rawToModel: {
    values: {
      created_at: (v: string) => new Date(v),
    },
  },
  modelToRaw: {
    values: { createdAt: (v: Date) => v.toISOString() },
  },
})
const messageDeserializer = msgSrlz.makeDeserializer()


const socket = ref<Socket | null>(null)
const ready = ref<boolean>(false)
const newMessages = ref<SectionMessageFullModel[]>([])
const updatedMessage = ref<SectionMessageFullModel | null>(null)
const subscribers = ref<number>(0)


export const useSectionMessage = (route: string) => {
  const notificationStore = useNotificationStore()
  const accountStore = useAccountStore()

  const connect = () => {
    subscribers.value++
    if (socket.value && !socket.value.isClosed) {
      return;
    }

    const { getToken } = useAuthStore()
    const { socket: _socket } = useWebSocket(route, getToken)
    console.log('new socket received')

    console.log('setting "message" watcher')
    watch(
      () => <MessageEvent<string> | null>socket.value?.message,
      (ev) => {
        console.log('new message event received:', ev)
        if (!ev) {
          return;
        }
        const message: SectionMessageWsNotification = JSON.parse(ev.data)
        switch (message.event) {
          case 'CONNECTING': {
            return;
          }
          case 'READY': {
            ready.value = true
            return;
          }
          case 'FORBIDDEN': {
            notificationStore.add({
              error: true,
              message: 'You are not allowed to perform this action',
            })
            console.error('error received:', message)
            return;
          }
          case 'ERROR': {
            notificationStore.add({ error: true })
            console.error('error received:', message)
            return;
          }
          case 'NEW_SECTION_MESSAGES': {
            const raw = message.payload.messages
            const ms = messageDeserializer.deserializeArray(raw)
            console.log('new section-messages received:', ms)
            newMessages.value = ms
            return;
          }
          case 'SECTION_MESSAGE_UPDATED': {
            const raw = message.payload.message
            const msg = messageDeserializer.deserializeRecord(raw)
            console.log('updated section-message received:', msg)
            updatedMessage.value = msg
            return;
          }
          default:
            console.log('unrecognized event:', message)
        }
      }
    )

    watch(
      () => _socket.value.isOpen,
      isOpen => ready.value = isOpen
    )

    console.log('setting "error" watcher')
    watch(
      () => <Event | null>_socket.value.error,
      (ev: Event | null) => {
        if (!ev) {
          return;
        }
        notificationStore.add({ error: true })
        console.log('onError called with event:', ev)
      }
    )

    console.log('connecting socket')
    _socket.value.connect()

    socket.value = _socket.value
    console.log('socket ref updated')
  }

  const post = (
    sectionId: string,
    message: SectionMessageBaseModel,
  ) => {
    if (!socket.value) {
      console.error('Cannot dispatch request. WS not connected.')
      return false
    }
    if (!ready.value) {
      console.error('Cannot dispatch request. WS connection not ready.')
      return false
    }
    const payload = { section_id: sectionId, message }
    socket.value.send('CREATE_SECTION_MESSAGE', payload)
    return true
  }

  const markRead = (messages: SectionMessageFullModel[]) => {
    if (!socket.value) {
      console.error('Cannot dispatch request. WS not connected.')
      return false
    }
    if (!ready.value) {
      console.error('Cannot dispatch request. WS connection not ready.')
      return false
    }

    if (!messages.length) {
      console.log('no messages to update')
      return true
    }

    if (messages.some(m => !m.viewedBy)) {
      console.error('cannot update. "viewed by" unknown.')
      return false
    }

    const userId = accountStore.user?.id
    if (!userId) {
      console.error('user not known')
      return false
    }

    const updates = messages
      .filter(m => !m.viewedBy?.some(u => u.id === userId))
      // TODO: fix serializer to accept partials
      .map(m => ({ id: m.id, viewed_by: [{ id: userId }] }))

    if (!updates.length) {
      console.log('nothing to update')
      return true
    }

    socket.value.send('UPDATE_SECTION_MESSAGES', { updates })
    return true
  }

  const disconnect = () => {
    if (--subscribers.value) {
      return;
    }
    socket.value?.disconnect()
    socket.value = null
  }

  return {
    connect,
    disconnect,
    ready,
    newMessages,
    updatedMessage,
    post,
    markRead,
  }
}
