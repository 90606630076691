import { usePrepList } from './prep-list'
import { useSectionMessage } from './section-message'


export const useWs = () => {
  const baseUrl = `${import.meta.env.VITE_BACKEND_WS_URL || ''}/ws`
  const wsURL = baseUrl.startsWith('/') ? `${window.location.origin.replace(/^http/, 'ws')}${baseUrl}` : baseUrl

  return {
    prepList: usePrepList(`${wsURL}/prep-list`),
    sectionMessage: useSectionMessage(`${wsURL}/section-message`),
  }
}
