<script setup lang="ts">
import { format, isBefore } from 'date-fns'
import type { PrepListFullModel } from '@/types/api/models/prep-list.full'

defineProps<{ prepLists: PrepListFullModel[] }>()
defineEmits<{ (e: 'list-selected', id: string): void }>()

const getIcon = (list: PrepListFullModel) => {
  if (list.archived) {
    // complete
    return 'check-circle'
  }
  if (isBefore(list.dueAt, Date.now())) {
    // overdue
    return 'times-circle'
  }
  return list.published ? 'play-circle' : 'pause-circle'
}
</script>

<style>
tr {
  cursor: pointer;
}
</style>

<template>
  <tr
    v-for="list in prepLists"
    :key="list.id"
    @click="$emit('list-selected', list.id)"
    >
      <td class="table-cell md:hidden">{{ format(list.dueAt, 'dd/MM') }}</td>
      <td class="hidden md:table-cell">{{ format(list.dueAt, 'd MMM, yy') }}</td>
      <td>
        <font-awesome-icon :icon="['fas', getIcon(list)]" />
      </td>
      <td class="hidden md:table-cell text-right">{{ list.prepItems.filter(i => i.finished).length }}</td>
      <td class="hidden md:table-cell w-0">/</td>
      <td class="hidden md:table-cell text-right w-0">{{ list.prepItems.length }}</td>
      <td>{{ list.name }}</td>
      <td class="hidden md:table-cell">{{ list.createdBy?.name }}</td>
      <td class="hidden md:table-cell">{{ list.section?.name }}</td>
  </tr>
</template>
